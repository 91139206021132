/* global __DEV__ */
// Simple React Native specific changes

const { version } = require('../../package.json')
const env = __DEV__ ? 'development' : 'production'
console.log(`Version ${version} - ${env} (${process.env.REACT_APP_ENV})`)

const AppConfig = {
  // font scaling override - RN default is on
  allowTextFontScaling: true,
  facebookLoginAppId: '1597335417057848',
  maxMoodboardSize: 26214400, // 25 MB
  privacyPolicyURL:
    'https://limaslab.gitlab.io/mosty/mosty-terms/politicas-de-privacidade',
  termsOfUseURL: 'https://www.mosty.com.br/termo-de-uso',
  iuguAccountId: '5B27C6B0EFF43C1EE04F7E3F1838DD88'
}

if (__DEV__) {
  // AppConfig.apiUri = 'http://localhost:9000/api/v2'
  AppConfig.apiUri = 'https://mosty-gateway-dev-7txfdm6rba-uw.a.run.app/api/v2'
  AppConfig.moodboardApiUri =
    'https://mosty-moodboard-dev-7txfdm6rba-uw.a.run.app/api/v2'
  AppConfig.s3Bucket = 'mostydev'
  AppConfig.facebookLoginRedirectUri = 'https://mosty-consultant-dev.now.sh'
  AppConfig.appleLoginRedirectUri = 'https://mosty-consultant-dev.vercel.app'
  AppConfig.appleLoginAppId = 'app.vercel.mosty-consultant-dev.auth'
  AppConfig.s3Region = 'us-west-2'
  AppConfig.moipPublicKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwcG7FPrFPs+y860DsRFp
  0W4qyEPbiSuTQg3PbU/jk5sY8K+WN+Y8V+Y3P9W8GLW/Rlp3DW3gUI/qVi4CE10I
  08e8Lg2VGe7JMyxaOVIMCEmbtu1OVfhh92W/UzZyRpSbsWV1m2sddqkBp7gZNIbY
  /qwOiYMRcHhzYoRLqK7UCry/pXMgthoPlxTeI3/mNu+AE3IU4TcJ8NmBQlYeyvqC
  Mh8Sm3dclKYlpFcEq/eU+JhGpP4CmdPf25fnxinHa2eHG+XD5e66t+722i0ZEPPd
  Hl1I76CAx70mAXhB+PX/Iv9PScPduEJuHtn0Ce0pjx/BCKv+C7X9y6gqt9durJVi
  gwIDAQAB
  -----END PUBLIC KEY-----`
  AppConfig.supportPhone = '7194109636'
  AppConfig.freeTrialPlanId = 10
  AppConfig.devPlanId = 426
} else {
  AppConfig.apiUri = 'https://mosty-gateway-7txfdm6rba-uw.a.run.app/api/v2'
  AppConfig.moodboardApiUri =
    'https://mosty-moodboard-7txfdm6rba-uw.a.run.app/api/v2'
  AppConfig.s3Bucket = 'mosty'
  AppConfig.facebookLoginRedirectUri = 'https://admin.mosty.app/login'
  AppConfig.appleLoginRedirectUri = 'https://admin.mosty.app'
  AppConfig.appleLoginAppId = 'app.mosty.admin.auth'
  AppConfig.s3Region = 'sa-east-1'
  AppConfig.moipPublicKey = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlKOt2wS+9393AeNkJzq+
  ICYFIjMee7FzUlb7FEiKQtVzOlnKQmRTofBCxL5wQoAX5OWQGNmvAVonS3Y9qwvz
  sAWb++eU2l8resEm22J72VqSw6j622q6i9LaCEpoVrX6WZKIstafiRQSoRkuSJky
  PtvkUKjA30pFG2EahKZLMAQU0PogAPqcAExpSfewFJopShCptZ9iJQSUsMx91ad6
  mbF7mfSI5c9PGfJ9x3NfBN0oQnsVhYkV/+rdED6HXQ3wm9ZDZc+qu/U20NzWMKXp
  lnwYwrHPpbRLcpofXC2Pss2GyxVbzdxb31oGoBlhS+quZnpn4AhN31DohuKuVFF4
  kQIDAQAB
  -----END PUBLIC KEY-----`
  AppConfig.supportPhone = '7194109636'
  AppConfig.freeTrialPlanId = 1
  AppConfig.fourSeasonsPlanId = 3
  AppConfig.fourSeasonsTenCustomersPlanId = 8
  AppConfig.fourSeasonsFiveCustomersPlanId = 10
  AppConfig.futuristicPlanId = 4
}

export default AppConfig
