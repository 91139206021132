import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addCard: ['user', 'card', 'paymentService', 'fullPricePlan'],
  setDefaultPaymentMethod: ['method', 'id'],
  setCards: ['cards']
})

export const PaymentTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  current: { method: null, id: null },
  cards: []
}

/* ------------- Reducers ------------- */

export const setDefaultPaymentMethod = (state, { method, id }) => {
  return { ...state, current: { method, id } }
}

export const setCards = (state, { cards }) => {
  const activeCards = cards.filter(item => item.status !== 'canceled')
  activeCards.sort((a, b) => b.id - a.id) // Sort by most recent

  return { ...state, cards: activeCards }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DEFAULT_PAYMENT_METHOD]: setDefaultPaymentMethod,
  [Types.SET_CARDS]: setCards
})
