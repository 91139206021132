import { useContext, useEffect } from 'react'
import { CarouselContext } from 'pure-react-carousel'

export default function CarouselCurrent (props) {
  const carouselContext = useContext(CarouselContext)
  useEffect(() => {
    function onChange () {
      props.setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return ''
}
