import React from 'react'
import { Text } from 'react-native'
import { PersistGate } from 'redux-persist/integration/react'
import ReduxPersistConfig from '../Config/ReduxPersist'

const renderLoading = () => {
  return <Text>Carregando...</Text>
}

const PersistGateResolver = ({ children, persistor }) => {
  // Simple helper to check if persistor is ok
  const persistorExists = persistor => {
    return persistor && typeof persistor.subscribe === 'function'
  }

  if (ReduxPersistConfig.active && persistorExists(persistor)) {
    return (
      <PersistGate loading={renderLoading()} persistor={persistor}>
        {children}
      </PersistGate>
    )
  } else {
    return children
  }
}

export default PersistGateResolver
