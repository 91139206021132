/* globals __DEV__ */
import React, { Component } from 'react'
import { View, Platform } from 'react-native'
import { hotjar } from 'react-hotjar'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { bindActionCreators } from 'redux'
// import LogRocket from 'logrocket'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { GoogleOAuthProvider } from '@react-oauth/google'

import AppNavigation from '../Navigation/AppNavigation'
import routeMap from '../Navigation/Routes'
import ToastHandler from '../Services/ToastHandler'
import PopupHandler from '../Services/PopupHandler'
import DeviceScreenListener from '../Services/DeviceScreenListener'
import Types from '../Redux/UserRedux'
import API from '../Services/Api'

// Styles
import styles from './Styles/RootContainerStyles'
import CookiePopup from '../Components/CookiePopup'
import CustomToast from '../Services/CustomToast'

class RootContainer extends Component {
  componentDidMount () {
    if (this.props.token) {
      this.getCurrentSubscription()
      this.getAchievementsList()
      this.getUserLevel()
    }

    if (!__DEV__) {
      hotjar.initialize(1554458, 6)
      // LogRocket.init('3kihng/mosty')
      ReactGA.initialize('UA-165255591-1')
      TagManager.initialize({ gtmId: 'GTM-KLQPKVF' })
    }

    const { token, user } = this.props

    if (token && user && user.id && user.email) {
      // Injects Sentry identity
      try {
        Sentry.configureScope(scope => {
          scope.setUser({
            id: user.id,
            email: user.email
          })
        })
      } catch (error) {
        console.error('Error injecting users Sentry identity', error)
      }

      // Injects LogRocket identity
      if (!__DEV__) {
        // try {
        //   LogRocket.identify(user.id, {
        //     name: user.name,
        //     email: user.email
        //   })
        // } catch (error) {
        //   console.error('Error injecting users LogRocket identity', error)
        // }
        try {
          ReactGA.set({ userId: user.id })
        } catch (error) {
          console.error(
            'Error injecting users Google Analytics identity',
            error
          )
        }
      }
    }

    if (Platform.OS === 'web') {
      this.checkAndStoreReferralCode()
    }
  }

  componentDidUpdate (prevProps) {
    if (
      (!prevProps.token && this.props.token) ||
      (prevProps.subscription &&
        this.props.subscription &&
        prevProps.subscription.id !== this.props.subscription.id)
    ) {
      this.getCurrentSubscription()
    }
  }

  checkAndStoreReferralCode = async () => {
    const queryString = await import('query-string')
    const { ref } = queryString.parse(window.location.search)
    if (ref) {
      this.props.setReferralCode(ref)
    }
  }

  getAchievementsList () {
    API.getAchievementsList().then(({ data }) => {
      const achievementsList = data.results
      this.props.setAchievementsList(achievementsList)
    })
  }

  getUserLevel () {
    API.getUserLevel()
      .then(({ data }) => {
        this.props.setLevel(data)
      })
      .catch(err => console.error(err))
  }

  getCurrentSubscription () {
    this.props.refreshSubscription()
  }

  render () {
    return (
      <GoogleOAuthProvider clientId='158151103289-a2a0fqi524loada3p0n2dj1p19q3u0hk.apps.googleusercontent.com'>
        <View style={styles.applicationView}>
          <DeviceScreenListener maxMobileWidth={768} />
          <AppNavigation routeMap={routeMap}>
            {/* Warning: CookieConsent work only at Web */}
            <CookiePopup />
            {/* PopupHandler and ToastHandler are here for the router to be their father */}
            <PopupHandler />
            <ToastHandler />
            <CustomToast />
            {/* <IntroHandler /> */}
            {/* <GamificationHandler /> */}
          </AppNavigation>
        </View>
      </GoogleOAuthProvider>
    )
  }
}

const mapSateToProps = state => ({
  token: state.user.token,
  user: state.user.user,
  subscription: state.user.subscription
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSubscription: Types.setSubscription,
      setPlan: Types.setPlan,
      setLevel: Types.setLevel,
      setAchievementsList: Types.setAchievementsList,
      setReferralCode: Types.setReferralCode,
      refreshSubscription: Types.refreshSubscription
    },
    dispatch
  )

export default connect(mapSateToProps, mapDispatchToProps)(RootContainer)
