import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import configureStore from './CreateStore'
import rootSaga from '../Sagas/'
import ReduxPersist from '../Config/ReduxPersist'

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  settings: require('./SettingsRedux').reducer,
  clients: require('./ClientsRedux').reducer,
  user: require('./UserRedux').reducer,
  payment: require('./PaymentRedux').reducer,
  scheduling: require('./SchedulingRedux').reducer,
  serviceBilling: require('./ServiceBillingRedux').reducer
})

export default async () => {
  let finalReducers = reducers
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig
    finalReducers = persistReducer(persistConfig, reducers)
  }

  let { store, sagasManager, sagaMiddleware, persistor } = await configureStore(
    finalReducers,
    rootSaga
  )

  // Expose store reference globally when running Cypress tests
  if (window && window.Cypress) {
    window.__store__ = store
    window.__loginByEmail__ = require('../Sagas/UserSagas').loginByEmailProcedure
    window.__create__ = require('../Services/Api').create
  }

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../Sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }

  return { store, persistor }
}
