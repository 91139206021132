import React from 'react'
import { toast, ToastBar, Toaster } from 'react-hot-toast'
import { Image, TouchableOpacity } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import CustomText from '../Components/CustomText'
import { Images, Colors } from '../Themes'

const CustomToast = () => {
  return (
    <Toaster
      reverseOrder={false}
      position='top-center'
      toastOptions={{
        duration: 3000,
        style: {
          width: 350,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(8px)',
          paddingTop: 22,
          paddingBottom: 22,
          paddingRight: 16,
          paddingLeft: 16,
          borderRadius: 24,
          color: '#fff'
        }
      }}
    >
      {t => (
        <ToastBar toast={t}>
          {({ message }) => (
            <>
              <Image
                source={
                  t.type === 'success' ? Images.sucessIcon : Images.errorIcon
                }
                style={{ width: 32, height: 32 }}
              />
              <CustomText style={{ color: '#fff' }}>{message}</CustomText>
              {t.type !== 'loading' && (
                <TouchableOpacity onClick={() => toast.dismiss(t.id)}>
                  <Icon
                    name='window-close'
                    size={22}
                    color={Colors.background}
                  />
                </TouchableOpacity>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default CustomToast
