import React, { Component, useEffect } from 'react'
import { View, Text } from 'react-native'

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
  WithStore
} from 'pure-react-carousel'
import Icon from 'react-native-vector-icons/MaterialIcons'
import 'pure-react-carousel/dist/react-carousel.es.css'
import './Styles/Carousel.css'
import { Colors } from '../Themes'
import CarouselCurrent from './CarouselCurrent'

const CarouselStoreWithoutProps = React.forwardRef((props, ref) => {
  useEffect(() => {
    props.setState &&
      props.setState({
        setCarouselStoreState: props.carouselStore.setStoreState
      })
  }, [])

  return false
})

const CarouselStore = WithStore(CarouselStoreWithoutProps)

class MyCarousel extends Component {
  constructor (props) {
    super(props)
    this.showDots =
      this.props.showDots !== undefined ? this.props.showDots : true
    this.showPages =
      this.props.showPages !== undefined ? this.props.showPages : false
    this.dragEnabled =
      this.props.dragEnabled !== undefined ? this.props.dragEnabled : true
    this.state = { currentSlide: props.currentSlide || 0 }
    this.infinite =
      this.props.infinite !== undefined ? this.props.infinite : true
  }

  componentDidMount = () => {
    if (this.props.autoNextSlide) {
      setInterval(() => {
        this.nextSlide(this.props.infinite)
      }, 10000)
    }
  }

  componentDidUpdate = () => {
    // This is to allow slide changes on miniature click or other external triggers
    if (
      this.props.currentSlide !== this.state.currentSlide &&
      this.props.miniatureClick
    ) {
      this.setCurrentSlide(this.props.currentSlide)
      this.props.setMiniatureClick(false)
    }
    this.setMiniatureImagesScrollView()
  }

  setCurrentSlide = currentSlide => {
    this.setState({ currentSlide })
  }

  // Sets the scroll distance so that we have the miniatures effect when clicking or changing pages
  // The distances are fixed because of the miniatures default size. If a size change is needed, just send it through props
  // 55 is the space of the miniature we want to leave seen and 20 is the margin
  setMiniatureImagesScrollView = () => {
    if (this.props.miniatureImagesScrollView) {
      this.props.miniatureImagesScrollView.current.scrollTo({
        y: 0,
        x:
          this.state.currentSlide === 0
            ? 0
            : this.state.currentSlide * 55 + (this.state.currentSlide - 1) * 20
      })
    }
  }

  nextSlide = infinite => {
    if (this.state.currentSlide + 1 < this.props.slides.length) {
      this.setState({ currentSlide: this.state.currentSlide + 1 })
    } else if (infinite) {
      this.setState({ currentSlide: 0 })
    }
  }

  prevSlide = () =>
    this.state.currentSlide - 1 >= 0 &&
    this.setState({ currentSlide: this.state.currentSlide - 1 })

  render () {
    const { slides } = this.props
    return (
      <View
        style={{
          height: '100%',
          width: '100%'
        }}
      >
        <CarouselProvider
          isIntrinsicHeight={this.props.isIntrinsicHeight}
          totalSlides={slides.length}
          naturalSlideWidth={this.props.naturalSlideWidth || 1}
          naturalSlideHeight={this.props.naturalSlideHeight || 1}
          currentSlide={this.state.currentSlide}
          dragEnabled={this.dragEnabled}
          ref={this.props.refToForward}
          hasMasterSpinner={this.props.loading}
          infinite={this.props.infinite && slides.length > 1}
        >
          <View
            style={[
              { paddingHorizontal: 24, height: '100%' },
              this.props.innerStyle
            ]}
          >
            <Slider
              style={{
                height: '100%'
              }}
              spinner={() => this.props.spinner}
            >
              {slides.map((value, idx) => (
                <Slide key={idx} index={idx} style={{ height: '100%' }}>
                  {value}
                </Slide>
              ))}
            </Slider>
            {this.props.renderButtonBack ? (
              this.props.renderButtonBack()
            ) : (
              <ButtonBack
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  border: 'none',
                  backgroundColor: 'transparent'
                }}
                data-test='carousel-back-button'
              >
                <Icon
                  size={32}
                  name='keyboard-arrow-left'
                  color={Colors.secondary}
                />
              </ButtonBack>
            )}
            {this.props.renderButtonNext ? (
              this.props.renderButtonNext()
            ) : (
              <ButtonNext
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  border: 'none',
                  backgroundColor: 'transparent'
                }}
                data-test='carousel-next-button'
              >
                <Icon
                  size={32}
                  name='keyboard-arrow-right'
                  color={Colors.secondary}
                />
              </ButtonNext>
            )}
          </View>
          {this.showDots && (
            <View
              style={{
                paddingTop: '16px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap',
                marginHorizontal: '16px',
                marginVertical: this.props.marginVerticalDots
                  ? this.props.marginVerticalDots
                  : '16px'
              }}
            >
              {slides.map((value, idx) => (
                <Dot
                  key={idx}
                  slide={idx}
                  style={{ borderRadius: '50%' }}
                  onClick={() => this.setState({ currentSlide: idx })}
                />
              ))}
            </View>
          )}
          {this.showPages && (
            <View
              style={{
                paddingTop: '6px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Text
                style={[
                  {
                    color: Colors.gray,
                    fontSize: 18,
                    lineHeight: 22
                  },
                  this.props.showPagesStyle
                ]}
              >
                Página {this.state.currentSlide + 1} de {slides.length}
                {/*TODO: If the page count breaks on some carousel check here*/}
                {/* Página {this.props.currentSlide + 1} de {slides.length} */}
              </Text>
            </View>
          )}
          <CarouselCurrent
            setCurrentSlide={currentSlide => {
              this.setState({ currentSlide })
              this.props.setCurrentSlide &&
                this.props.setCurrentSlide(currentSlide)
            }}
          />
          <CarouselStore setState={this.props.setState} />
        </CarouselProvider>
      </View>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <MyCarousel {...props} refToForward={ref} />
))
