/* global __DEV__ */
import DebugConfig from './DebugConfig'

if (__DEV__) {
  // If ReactNative's yellow box warnings are too much, it is possible to turn
  // it off, but the healthier approach is to fix the warnings.  =)
  console.disableYellowBox = !DebugConfig.yellowBox
}

export const sentryDSNWeb =
  'https://cc462a6be24e4b448b1fa8cf7f1cee9e@o533248.ingest.sentry.io/5679332'
export const sentryDSNNative = null
