import React from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { Colors } from '../Themes'

const styles = StyleSheet.create({
  container: {
    top: 96,
    width: '100%',
    height: '100%'
  }
})

const LoadingContainer = ({ containerStyle, color }) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <ActivityIndicator size='large' color={color || Colors.primary} />
    </View>
  )
}

export default LoadingContainer
