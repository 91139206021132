import React, { useState, useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import { Text, View } from 'react-native'
import { Link } from 'react-router-dom'
import AppConfig from '../Config/AppConfig'
import { openLink } from '../Services/Helpers'
import { Colors, Fonts } from '../Themes'
import IconMaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons'
import CustomText from './CustomText'

const CookiePopup = () => {
  const [showCookieConsent, setShowCookieConsent] = useState(false)

  useEffect(() => {
    // Verifique se a aplicação não está dentro de um iFrame
    if (window.self === window.top) {
      // A aplicação não está dentro de um iFrame, verifique o cookie de aceitação de cookies
      const cookieAccepted = document.cookie.includes('CookieConsent=true')
      // Se o cookie não existir, defina showCookieConsent como true para exibir o popup
      if (!cookieAccepted) {
        setShowCookieConsent(true)
      }
    } else {
      // A aplicação está dentro de um iFrame, não exiba o popup
      document.cookie =
        'CookieConsent=true; expires=Thu, 01 Jan 2099 00:00:00 UTC; path=/'
      setShowCookieConsent(false)
    }
  }, [])

  return (
    <>
      {showCookieConsent && (
        <CookieConsent
          location='bottom'
          buttonText='Concordar'
          style={{
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(8px)',
            flexDirection: 'column',
            borderRadius: '30px 30px 0 0',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          buttonStyle={{
            color: '#FFFFFF',
            backgroundColor: '#96BD90',
            borderRadius: '30px',
            width: 263,
            height: 44,
            fontWeight: '600',
            margin: 0,
            marginBottom: 75,
            fontFamily: Fonts.type.base
          }}
          contentStyle={{
            flex: 0,
            margin: 0,
            width: '100%'
          }}
          sameSite='strict'
          enableDeclineButton
          declineButtonText={
            <IconMaterialCommunity
              name='window-close'
              size={22}
              color={Colors.background}
            />
          }
          declineButtonStyle={{
            backgroundColor: 'transparent',
            position: 'absolute',
            right: 0,
            top: 0
          }}
        >
          <View
            style={{ marginBottom: 40, marginTop: 56, marginHorizontal: 56 }}
          >
            <CustomText
              style={{
                fontSize: 16,
                textAlign: 'center',
                color: Colors.white,
                lineHeight: 24
              }}
            >
              Este site usa cookies e dados pessoais de acordo com a nossa
              <br />
              <CustomText
                style={{
                  color: '#FFFFFF',
                  fontSize: 16,
                  textDecorationLine: 'underline'
                }}
                onClick={() =>
                  openLink(AppConfig.privacyPolicyURL, { target: '_blank' })
                }
              >
                Política de Privacidade
              </CustomText>
              .
            </CustomText>
          </View>
        </CookieConsent>
      )}
    </>
  )
}

export default CookiePopup
