/*
O objetivo deste arquivo é fazer o polyfill de algumas funcionalidades que podem dar problema nos browsers mais antigos!
(Na verdade, browsers como o Edge muitas vezes não implementam coisas importantes, até em versões mais novas)

No geral os códigos são baseados no developer.mozilla.org
*/

// Desabilitamos linter para esse arquivo
/* eslint-disable */

// Implement finally if not
Promise.prototype.finally =
  Promise.prototype.finally ||
  {
    // eslint-disable-line
    finally (fn) {
      const onFinally = cb => Promise.resolve(fn()).then(cb)
      return this.then(
        result => onFinally(() => result),
        reason =>
          onFinally(() => {
            throw reason
          })
      )
    }
  }.finally

// Object.entries if not
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
if (!Object.entries) {
  Object.entries = function (obj) {
    var ownProps = Object.keys(obj)
    var i = ownProps.length
    var resArray = new Array(i) // preallocate the Array
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]]
    }

    return resArray
  }
}

// Object.entries if not
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
if (!Object.keys) {
  Object.keys = (function () {
    'use strict'
    var hasOwnProperty = Object.prototype.hasOwnProperty
    var hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString')
    var dontEnums = [
      'toString',
      'toLocaleString',
      'valueOf',
      'hasOwnProperty',
      'isPrototypeOf',
      'propertyIsEnumerable',
      'constructor'
    ]
    var dontEnumsLength = dontEnums.length

    return function (obj) {
      if (
        typeof obj !== 'function' &&
        (typeof obj !== 'object' || obj === null)
      ) {
        throw new TypeError('Object.keys called on non-object')
      }

      var result = []
      var prop
      var i

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop)
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i])
          }
        }
      }
      return result
    }
  })()
}
