/* global __DEV__ */
import Settings from '../Redux/SettingsRedux'
import { resolvePopupHaveACustomerUsingTheApp } from '../Components/PopupHaveACustomerUsingTheApp'

export const TYPES = __DEV__
  ? {
      SEE_CLIENT: 1,
      ADD_COLORING: 2,
      HAVE_A_CUSTOMER_USING_THE_APP: 4,
      ADD_EVENT: 5,
      ADD_SILHOUETTE: 6,
      ADD_MOODBOARD: 7,
      ADD_CLOSET: 8,
      ADD_SHOPPING_LIST: 9,
      ADD_NOTES: 10,
      RECOMMEND_THE_APP: 11,
      HAVE_FOUR_REGISTERED_CLIENTS: 12
    }
  : {
      // [TODO] Set values for production
      SEE_CLIENT: 1,
      ADD_COLORING: 2,
      HAVE_A_CUSTOMER_USING_THE_APP: 3,
      ADD_EVENT: 4,
      ADD_SILHOUETTE: 5,
      ADD_MOODBOARD: 6,
      ADD_CLOSET: 7,
      ADD_SHOPPING_LIST: 8,
      ADD_NOTES: 9,
      RECOMMEND_THE_APP: 10,
      HAVE_FOUR_REGISTERED_CLIENTS: 11
    }

export const getGoal = (
  namespace = 'carousel' || 'challengesScreen',
  pendingAchievements
) => {
  const goals =
    namespace === 'carousel'
      ? filterAndSortGoals(pendingAchievements, sequenceForCarousel)
      : filterAndSortGoals(pendingAchievements, sequenceForChallengesScreen)

  const handlers = {}
  handlers[TYPES.SEE_CLIENT] = handlerSeeClient
  handlers[TYPES.ADD_COLORING] = handlerAddColoring
  handlers[
    TYPES.HAVE_A_CUSTOMER_USING_THE_APP
  ] = handlerHaveACustomerUsingTheApp
  handlers[TYPES.ADD_EVENT] = handlerAddEvent
  handlers[TYPES.ADD_SILHOUETTE] = handlerAddSilhouette
  handlers[TYPES.ADD_MOODBOARD] = handlerAddMoodboard
  handlers[TYPES.ADD_CLOSET] = handlerAddCloset
  handlers[TYPES.ADD_SHOPPING_LIST] = handlerAddShoppingList
  handlers[TYPES.ADD_NOTES] = handlerAddNotes
  handlers[TYPES.RECOMMEND_THE_APP] = handlerRecommendTheApp
  handlers[
    TYPES.HAVE_FOUR_REGISTERED_CLIENTS
  ] = handlerHaveFourRegisteredClients

  if (namespace === 'carousel') {
    if (goals.length === 0) {
      const action = defaultGoalHandler()
      return { label: 'Complete mais desafios para subir de nível!', action }
    }
    const action = goalHandler(goals[0], handlers)
    return { label: goals[0].achievement.label, action }
  } else {
    return goals.reduce((obj, item) => {
      obj[item.achievementId] = goalHandler(item, handlers)
      return obj
    }, {})
  }
}

const goalHandler = (goal, handlers) => {
  const handler = handlers[goal.achievementId]
  return handler(goal)
}
const defaultGoalHandler = () => ({ path: '/desafios' })
const handlerSeeClient = () => ({ path: '/clientes/:id' })
const handlerAddColoring = () => ({
  path: '/clientes',
  next: '/clientes/:id/coloracao/tipo'
})
const handlerAddEvent = () => ({
  path: '/clientes',
  next: '/compromissos/novo'
})
const handlerAddSilhouette = () => ({
  path: '/clientes',
  next: '/clientes/:id/sobre/tipo'
})

// [TODO] How to display the popup that explain the challenge
const handlerHaveACustomerUsingTheApp = () => ({
  path: '/clientes',
  beforeStartReduxAction: Settings.openPopup(
    resolvePopupHaveACustomerUsingTheApp()
  )
})

const handlerAddMoodboard = () => ({
  path: '/clientes',
  next: '/clientes/:id/guia-de-estilo/confirmacao'
})
const handlerAddCloset = () => ({
  path: '/clientes',
  next: '/clientes/:id/closet/tipo'
})
const handlerAddShoppingList = () => ({
  path: '/clientes',
  next: '/clientes/:id/lista-de-compras/tipo'
})
const handlerAddNotes = () => ({
  path: '/clientes',
  next: '/clientes/:id/anotacoes'
})
const handlerRecommendTheApp = () => ({ path: '/recomendar', next: '' })
const handlerHaveFourRegisteredClients = () => ({
  path: '/clientes/novo/cadastro',
  next: ''
})

// Ids of achievements
const sequenceForCarousel = [
  TYPES.SEE_CLIENT,
  TYPES.ADD_COLORING,
  TYPES.ADD_EVENT,
  TYPES.ADD_SILHOUETTE
]

const sequenceForChallengesScreen = [
  TYPES.SEE_CLIENT,
  TYPES.ADD_COLORING,
  TYPES.HAVE_A_CUSTOMER_USING_THE_APP,
  TYPES.ADD_EVENT,
  TYPES.ADD_SILHOUETTE,
  TYPES.ADD_MOODBOARD,
  TYPES.ADD_CLOSET,
  TYPES.ADD_SHOPPING_LIST,
  TYPES.ADD_NOTES,
  TYPES.RECOMMEND_THE_APP,
  TYPES.HAVE_FOUR_REGISTERED_CLIENTS
]

const filterAndSortGoals = (goals, sequence) => {
  return sequence
    .map(id => goals.find(goal => goal.achievementId === id))
    .filter(item => !!item)
}

export const resolveReward = type => {
  const rewards = {
    FREE_TRIAL_EXTENSION: '+ 5 dias de teste grátis no app',
    CONTENT_ACCESS: 'acesso à aba de conteúdos',
    POP_SOCKET: 'pop Socket Mosty*',
    NEW_COURSE: 'curso com Ju Berman*'
  }

  return rewards[type]
}

export const isPremiumReward = type =>
  ['POP_SOCKET', 'NEW_COURSE'].includes(type)
export const isFreeTrialReward = type =>
  ['FREE_TRIAL_EXTENSION', 'CONTENT_ACCESS'].includes(type)

export const resolveConcludedChallenge = type => {
  const rewards = {}
  rewards[TYPES.SEE_CLIENT] =
    'Você acabou de acessar o menu de sua primeira cliente! É aqui que você adicionará todas as informações referentes à sua consultoria. Sempre que adicionar uma nova cliente, ela poderá criar a conta dela no Aplicativo da Cliente e acessar todos os dados disponibilizados por você.'
  rewards[TYPES.ADD_COLORING] =
    'Você adicionou a coloração com sucesso! Agora sua cliente já pode conferir a própria coloração pessoal no Aplicativo da Cliente.'
  rewards[TYPES.HAVE_A_CUSTOMER_USING_THE_APP] =
    'Sua cliente confirmou a conta, e agora também faz parte da Mosty!'
  rewards[TYPES.ADD_EVENT] =
    'Você adicionou o seu primeiro compromisso! Você pode acompanhar seus compromissos na seção Compromissos'
  rewards[TYPES.ADD_SILHOUETTE] =
    'Você configurou a silhueta com sucesso! Agora sua cliente já pode conferir as informações sobre a própria silhueta no Aplicativo da Cliente.'
  rewards[TYPES.ADD_MOODBOARD] = 'Você adicionou o guia de estilo com sucesso!'
  rewards[TYPES.ADD_CLOSET] = 'Você alterou o Closet Virtual com sucesso!'
  rewards[TYPES.ADD_SHOPPING_LIST] =
    'Você alterou a lista de compras com sucesso!'
  rewards[TYPES.ADD_NOTES] =
    'Você adicionou a sua primeira anotação! Sempre que precisar, basta voltar aqui e as suas anotações estarão guardadas.'
  rewards[TYPES.RECOMEND_THE_APP] =
    'Você convidou uma amiga para se juntar a Mosty!'
  rewards[TYPES.HAVE_FOUR_REGISTERED_CLIENTS] =
    'Agora você tem 4 clientes cadastradas no app!'

  return rewards[type]
}
