import React from 'react'
import ReactDOM from 'react-dom'
import { TextInput } from 'react-native'

import App from './Containers/App'

// Generate required css
import iconFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import iconFontMCI from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import iconFontEnty from 'react-native-vector-icons/Fonts/Entypo.ttf'
import iconFontMat from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'
import iconFontIo from 'react-native-vector-icons/Fonts/Ionicons.ttf'
import iconFontEvil from 'react-native-vector-icons/Fonts/EvilIcons.ttf'

// Import our polyfill file
import './polyfill'

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: FontAwesome;
}`

const iconFontStylesMCI = `@font-face {
  src: url(${iconFontMCI});
  font-family: Material Design Icons;
}`

const iconFontStylesEnty = `@font-face {
  src: url(${iconFontEnty});
  font-family: Entypo;
}`

const iconFontStylesMat = `@font-face {
  src: url(${iconFontMat});

  font-family: Material Icons;
}`

const iconFontStylesIo = `@font-face {
  src: url(${iconFontIo});

  font-family: Ionicons;
}`

const iconFontStylesEvil = `@font-face {
  src: url(${iconFontEvil});

  font-family: EvilIcons;
}`

// Create stylesheet
const style = document.createElement('style')
const styleMCI = document.createElement('style')
const styleEnty = document.createElement('style')
const styleMat = document.createElement('style')
const styleIo = document.createElement('style')
const styleEvil = document.createElement('style')

style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

styleMCI.type = 'text/css'
if (styleMCI.styleSheet) {
  styleMCI.styleSheet.cssText = iconFontStylesMCI
} else {
  styleMCI.appendChild(document.createTextNode(iconFontStylesMCI))
}

styleEnty.type = 'text/css'
if (styleEnty.styleSheet) {
  styleEnty.styleSheet.cssText = iconFontStylesEnty
} else {
  styleEnty.appendChild(document.createTextNode(iconFontStylesEnty))
}

styleMat.type = 'text/css'
if (styleMat.styleSheet) {
  styleMat.styleSheet.cssText = iconFontStylesMat
} else {
  styleMat.appendChild(document.createTextNode(iconFontStylesMat))
}

styleIo.type = 'text/css'
if (styleIo.styleSheet) {
  styleIo.styleSheet.cssText = iconFontStylesIo
} else {
  styleIo.appendChild(document.createTextNode(iconFontStylesIo))
}

styleEvil.type = 'text/css'
if (styleEvil.styleSheet) {
  styleEvil.styleSheet.cssText = iconFontStylesEvil
} else {
  styleEvil.appendChild(document.createTextNode(iconFontStylesEvil))
}

// Inject stylesheet
document.head.appendChild(style)
document.head.appendChild(styleMCI)
document.head.appendChild(styleEnty)
document.head.appendChild(styleMat)
document.head.appendChild(styleIo)
document.head.appendChild(styleEvil)

// Implement finally if not
Promise.prototype.finally =
  Promise.prototype.finally ||
  {
    // eslint-disable-line
    finally (fn) {
      const onFinally = cb => Promise.resolve(fn()).then(cb)
      return this.then(
        result => onFinally(() => result),
        reason =>
          onFinally(() => {
            throw reason
          })
      )
    }
  }.finally

const oldTextInputRender = TextInput.prototype.render
TextInput.prototype.render = function (...args) {
  const origin = oldTextInputRender.call(this, ...args)
  return React.cloneElement(origin, {
    style: {
      outline: 'none',
      ...origin.props.style
    }
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
