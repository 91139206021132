import React, { Component } from 'react'
import RoundedButtonStyles from '../../Containers/Styles/RoundedButtonStyles'
import { Colors, Fonts } from '../../Themes'
import IconMaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons'
import CustomText from '../CustomText'
import {
  StyleSheet,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Image,
  ScrollView,
  Dimensions,
  TextInput
} from 'react-native'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SettingsRedux from '../../Redux/SettingsRedux'
import PromiseButton from '@limaslab/react-native-promise-button'

const setOverflowVisible = () => {
  document.body.setAttribute('style', 'overflow: visible;')
}

const setOverflowHidden = () => {
  document.body.setAttribute('style', 'overflow: hidden;')
}

class BasicPopup extends Component {
  onClose = () => {
    if (!this.props.isLoading && this.props.couldClose) {
      this.props.onClose()
      this.props.closePopup()
    }
  }

  componentDidMount () {
    setOverflowHidden()
  }

  componentWillUnmount () {
    setOverflowVisible()
  }

  renderButtonLoading = () => {
    return (
      <View
        style={[
          RoundedButtonStyles.button,
          this.props.styleButton,
          {
            height: 44,
            marginTop: 32
          }
        ]}
      >
        <ActivityIndicator size='small' color={Colors.background} />
      </View>
    )
  }

  renderButton = () => {
    return (
      <TouchableOpacity
        style={[
          RoundedButtonStyles.button,
          this.props.styleButton,
          {
            height: this.props.textButton.length > 20 ? 56 : 44,
            marginTop: 32,
            marginBottom: this.props.secondaryTextButton ? 0 : 32,
            flexDirection: this.props.iconButton ? 'row' : 'column'
          }
        ]}
        onPress={() => this.props.buttonAction()}
      >
        {this.props.iconButton && this.props.iconButton}
        <CustomText
          style={[
            RoundedButtonStyles.buttonText,
            {
              color: this.props.textButtonColor ?? Colors.background,
              textAlign: 'center',
              fontSize: this.props.textButton.length > 20 ? 14 : 15,
              marginLeft: this.props.iconButton ? 12 : 0
            }
          ]}
        >
          {' '}
          {this.props.textButton}
        </CustomText>
      </TouchableOpacity>
    )
  }

  render () {
    const windowDimensions = Dimensions.get('window')
    const height = windowDimensions.height

    return (
      <View style={{ width: '100%', height: '100%', zIndex: 9 }}>
        <TouchableWithoutFeedback onPress={this.onClose}>
          <View style={styles.backdrop} />
        </TouchableWithoutFeedback>
        <View style={styles.container} pointerEvents='box-none'>
          <View style={styles.boxPopup}>
            <TouchableOpacity style={styles.iconClose} onPress={this.onClose}>
              <IconMaterialCommunity
                name='window-close'
                size={22}
                color={Colors.textColorInput}
              />
            </TouchableOpacity>

            {this.props.customContent ? (
              <View style={{ width: '100%', height: '100%' }}>
                {this.props.customContent}
              </View>
            ) : (
              <View style={{ width: '100%', height: '100%' }}>
                <CustomText style={styles.textH1}>
                  {this.props.titleIcon}
                  {this.props.textH1}
                </CustomText>
                <ScrollView style={{ maxHeight: height - 250, marginTop: 8 }}>
                  <CustomText style={styles.textH2}>
                    {this.props.textH2}
                  </CustomText>
                  {this.props.imageSource && (
                    <Image
                      source={this.props.imageSource}
                      style={{
                        height: 120,
                        marginTop: 24,
                        marginBottom: this.props.hasInputText ? 24 : 0
                      }}
                      resizeMode={'contain'}
                    />
                  )}

                  {this.props.hasInputText && (
                    <TextInput
                      style={{
                        width: '100%',
                        height: 100,
                        borderColor: Colors.textColorInput,
                        borderWidth: 1,
                        padding: 10,
                        borderRadius: 16,
                        textAlignVertical: 'top',
                        color: Colors.secondary
                      }}
                      placeholder='Ex: Gostei muito da saia...'
                      multiline
                      numberOfLines={4}
                      onChangeText={text => this.props.inputText(text)}
                      underlineColorAndroid='rgba(0,0,0,0)'
                    />
                  )}
                </ScrollView>
                {this.props.isLoading
                  ? this.renderButtonLoading()
                  : this.renderButton()}
                {this.props.secondaryTextButton && (
                  <TouchableOpacity
                    style={[
                      RoundedButtonStyles.secondaryButton,
                      { height: 44, marginBottom: 32, marginTop: 16 }
                    ]}
                    onPress={() => this.props.secondaryButtonAction()}
                  >
                    <CustomText
                      style={[
                        RoundedButtonStyles.buttonText,
                        {
                          color: Colors.textPurpleColor,
                          fontSize:
                            this.props.secondaryTextButton.length > 20 ? 14 : 15
                        }
                      ]}
                    >
                      {' '}
                      {this.props.secondaryTextButton}
                    </CustomText>
                  </TouchableOpacity>
                )}
              </View>
            )}
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: 'rgba(74, 74, 74, 0.7)',
    backdropFilter: 'blur(15px)'
  },
  container: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    paddingHorizontal: 16
  },
  boxPopup: {
    width: 327,
    backgroundColor: Colors.background,
    borderRadius: 12,
    paddingHorizontal: 32
  },
  iconClose: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 9
  },
  textH1: {
    marginTop: 32,
    fontWeight: 'bold',
    fontSize: 24,
    fontFamily: Fonts.type.base
  },
  textH2: {
    marginTop: 8,
    fontSize: 16,
    fontFamily: Fonts.type.base
  }
})

BasicPopup.defaultProps = {
  isLoading: false,
  onClose: () => {},
  couldClose: true,
  showClose: true
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(BasicPopup)
