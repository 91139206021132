import React, { Component } from 'react'
import { View, Text, ActivityIndicator, TouchableOpacity } from 'react-native'
import Loadable from 'react-loadable'
import colors from '../../Themes/Colors'
import Skeletons from '../../Components/Skeletons'

const loadingComponentWrapper = (
  Loading,
  Error,
  { pastDelay, timedOut, error }
) => {
  if (error || timedOut) {
    console.error('Error lazy loading component', error)
    return <Error />
  } else if (pastDelay) {
    return <Loading />
  } else {
    return null
  }
}

const DefaultErrorComponent = () => {
  return (
    <View
      style={{ width: '100%', height: '100%', backgroundColor: colors.white }}
    >
      <View style={{ paddingHorizontal: 32, alignItems: 'center' }}>
        <Text style={{ marginTop: 8, fontSize: 32, color: colors.primary }}>
          Desculpe-nos!
        </Text>
        <Text style={{ marginTop: 8, fontSize: 24, color: colors.primary }}>
          Um erro ocorreu ao carregar a página. Por favor, tente novamente!
        </Text>
        <TouchableOpacity
          onPress={() => window.location.reload(true)}
          style={{
            marginTop: 12,
            paddingHorizontal: 14,
            paddingVertical: 8,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.primary,
            borderRadius: 6
          }}
        >
          <Text style={{ fontSize: 18, color: '#fff' }}>Tentar novamente</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const DefaultLoadingComponent = () => {
  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.white
      }}
    >
      <ActivityIndicator size={32} color={colors.primary} />
    </View>
  )
}

const GeneralLoadingComponent = () => {
  return <Skeletons />
}

class InitialLoadingComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pointsCount: 0
    }
  }

  componentDidMount () {
    setInterval(
      () => this.setState({ pointsCount: (this.state.pointsCount + 1) % 3 }),
      500
    )
  }

  render () {
    return (
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.white
        }}
      >
        <View style={{ width: 16 }}>
          {this.state.pointsCount >= 0 && (
            <Text style={{ marginTop: 8, fontSize: 64, color: colors.primary }}>
              .
            </Text>
          )}
        </View>
        <View style={{ width: 16 }}>
          {this.state.pointsCount >= 1 && (
            <Text style={{ marginTop: 8, fontSize: 64, color: colors.primary }}>
              .
            </Text>
          )}
        </View>
        <View style={{ width: 16 }}>
          {this.state.pointsCount >= 2 && (
            <Text style={{ marginTop: 8, fontSize: 64, color: colors.primary }}>
              .
            </Text>
          )}
        </View>
      </View>
    )
  }
}

const retryWrapper = (fn, retriesLeft = 5, interval = 500) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          } else {
            console.warn(
              'Error lazy loading component. Attempts left',
              retriesLeft,
              'Error:',
              error
            )
          }
          // Passing on "reject" is the important part
          retryWrapper(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

const loadable = (
  component,
  loadingComponent = DefaultLoadingComponent,
  errorComponent = DefaultErrorComponent
) =>
  Loadable({
    loader: () => retryWrapper(component),
    loading: params =>
      loadingComponentWrapper(loadingComponent, errorComponent, params),
    delay: 600
  })

const generalLoadable = (
  component,
  loadingComponent = GeneralLoadingComponent,
  errorComponent = DefaultErrorComponent
) =>
  Loadable({
    loader: () => retryWrapper(component),
    loading: params =>
      loadingComponentWrapper(loadingComponent, errorComponent, params),
    delay: 600
  })

export { loadable, InitialLoadingComponent, generalLoadable }
