import React from 'react'
import {
  TouchableWithoutFeedback,
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  ViewPropTypes
} from 'react-native'
import PropTypes from 'prop-types'
import ContentCarousel from '../Carousel'
import LoadingContainer from '../LoadingContainer'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import LinearGradient from 'react-native-linear-gradient'
import SettingsRedux from '../../Redux/SettingsRedux'
import Colors from '../../Themes/Colors'
import _ from 'lodash'

const style = StyleSheet.create({
  titleContainer: {
    marginBottom: 8,
    width: '100%',
    alignItems: 'center'
  },
  title: {
    fontSize: 20,
    color: Colors.primary,
    textAlign: 'center'
  },
  backdrop: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.gray,
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  container: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    paddingHorizontal: 16
  },
  contentContainer: {
    maxWidth: 768,
    backgroundColor: Colors.white,
    borderRadius: 10,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderColor: Colors.dark
  },
  closeButtonContainer: {
    top: -20,
    position: 'absolute',
    right: -32,
    zIndex: 10,
    backgroundColor: 'transparent'
  },
  closeIconContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 16
  },
  knowMore: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
    marginTop: 32,
    height: 36
  },
  knowMoreText: {
    color: Colors.primary,
    fontSize: 18,
    textAlign: 'center'
  },
  knowMoreIcon: {
    marginLeft: 10,
    marginTop: 6
  },
  carouselCard: {
    height: '100%',
    display: 'flex',
    borderRadius: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  continueExploring: {
    width: '100%',
    maxWidth: 400,
    alignSelf: 'center',
    height: 36,
    backgroundColor: Colors.primary,
    borderRadius: 20,
    marginBottom: 8,
    marginTop: 32,
    justifyContent: 'center',
    alignItems: 'center'
  },
  continueExploringText: {
    color: 'white',
    fontSize: 18
  }
})

class Carousel extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      currentSlide: 0
    }
  }

  isLastSlide = () => this.state.currentSlide === this.props.slides.length - 1

  onClose = () => {
    if (!this.props.isLoading && this.props.couldClose) {
      this.props.onClose()
      this.props.closePopup()
    }
  }

  renderLoading = () => {
    return <ActivityIndicator size='large' color={Colors.primary} />
  }

  createSlideSpinner = () => {
    return (
      <LinearGradient
        colors={['#6A3977', '#834F8A', '#A36FAE']}
        style={[
          style.carouselCard,
          {
            opacity: 0.85,
            backgroundColor: Colors.primary,
            padding: 8,
            display: 'flex'
          }
        ]}
      >
        <View style={{ flex: 1, marginTop: 62 }}>
          <LoadingContainer color='#ffffff' />
        </View>
      </LinearGradient>
    )
  }

  computeCardAspectWidthRatio = () => {
    return this.props.isMobile ? 1.25 : 2
  }

  computeCardAspectHeightRatio = () => {
    return this.props.isMobile ? 1.375 : 2.2
  }

  renderImageOrGifSlide = src => {
    return (
      <View
        style={{
          height: '100%',
          display: 'flex',
          borderRadius: 8,
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <img
          src={src}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center',
            borderRadius: 8
          }}
        />
      </View>
    )
  }

  renderVideoSlide = src => {
    return (
      <iframe
        width='100%'
        height='100%'
        src={`https://www.youtube.com/embed/${src}`}
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
      ></iframe>
    )
  }

  renderContent = () => {
    const { title, showClose, icon, slides } = this.props
    const cardAspectWidthRatio = this.computeCardAspectWidthRatio()
    const cardAspectHeightRatio = this.computeCardAspectHeightRatio()

    return (
      <View style={{ flex: 1, width: '100%', height: '100%', paddingTop: 8 }}>
        {icon && <View style={{ position: 'absolute' }}>{icon}</View>}
        {title && (
          <View style={style.titleContainer}>
            <Text style={style.title}>
              <b>{title}</b>
              {slides[this.state.currentSlide].description && (
                <p
                  style={{
                    fontSize: 14,
                    maxWidth: 400,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 65
                  }}
                >
                  {slides[this.state.currentSlide].description}
                </p>
              )}
            </Text>
          </View>
        )}
        <View
          style={{
            width: '100%',
            maxWidth: 400,
            alignSelf: 'center'
          }}
        >
          <ContentCarousel
            setCurrentSlide={currentSlide => this.setState({ currentSlide })}
            slides={slides.map(slide => {
              return slide.type === 'video'
                ? this.renderVideoSlide(slide.src)
                : this.renderImageOrGifSlide(slide.src)
            })}
            naturalSlideWidth={cardAspectWidthRatio}
            naturalSlideHeight={cardAspectHeightRatio}
            spinner={this.createSlideSpinner()}
          />
        </View>
        {this.isLastSlide() ? (
          <TouchableOpacity
            style={style.continueExploring}
            onPress={this.props.closePopup}
          >
            <Text style={style.continueExploringText}>
              Continuar explorando
            </Text>
          </TouchableOpacity>
        ) : (
          <View style={style.knowMore}>
            <Text style={style.knowMoreText}>
              Arraste para o lado e saiba mais
            </Text>
            <Icon
              name='arrow-forward'
              color={Colors.primary}
              size={25}
              style={style.knowMoreIcon}
            />
          </View>
        )}
        {showClose && (
          <TouchableOpacity
            onPress={this.onClose}
            style={style.closeButtonContainer}
            data-test='modal-close-button'
          >
            <View style={style.closeIconContainer}>
              <Icon name='close' color={Colors.secondary} size={32} />
            </View>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  render () {
    const { isLoading } = this.props

    return (
      <View style={{ width: '100%', height: '100%' }}>
        <TouchableWithoutFeedback onPress={this.onClose}>
          <View style={[style.backdrop, this.props.backdropStyle]} />
        </TouchableWithoutFeedback>
        <View
          style={[style.container, this.props.containerStyle]}
          pointerEvents='box-none'
        >
          <View style={[style.contentContainer, this.props.style]}>
            {isLoading ? this.renderLoading() : this.renderContent()}
          </View>
        </View>
      </View>
    )
  }
}

Carousel.propType = {
  title: PropTypes.string,
  icon: PropTypes.element,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  couldClose: PropTypes.bool,
  slides: PropTypes.array,
  style: ViewPropTypes.style,
  containerStyle: ViewPropTypes.style,
  backdropStyle: ViewPropTypes.style
}

Carousel.defaultProps = {
  title: undefined,
  icon: undefined,
  isLoading: false,
  onClose: () => {},
  couldClose: true,
  showClose: true
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Carousel)
