import React from 'react'
import {
  TouchableWithoutFeedback,
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  ViewPropTypes
} from 'react-native'
import PropTypes from 'prop-types'

import Icon from 'react-native-vector-icons/MaterialIcons'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SettingsRedux from '../../Redux/SettingsRedux'
import Colors from '../../Themes/Colors'

const style = StyleSheet.create({
  titleContainer: {
    marginBottom: 8,
    width: '100%',
    alignItems: 'center'

    // borderStyle: 'solid',
    // borderBottomWidth: 1,
    // borderColor: Colors.gray
  },
  title: {
    fontSize: 20,
    color: Colors.primary,
    textAlign: 'center'
  },
  backdrop: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.gray,
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  container: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    paddingHorizontal: 16
  },
  contentContainer: {
    maxWidth: 768,
    backgroundColor: Colors.white,
    borderRadius: 10,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 8,
    // borderStyle: 'solid',
    // borderWidth: 2,
    borderColor: Colors.dark
  },
  closeButtonContainer: {
    top: -20,
    position: 'absolute',
    right: -32,
    zIndex: 10,
    backgroundColor: 'transparent'
  },
  closeIconContainer: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 16
  }
})

class Simple extends React.Component {
  onClose = () => {
    if (!this.props.isLoading && this.props.couldClose) {
      this.props.onClose()
      this.props.closePopup()
    }
  }

  renderLoading = () => {
    return <ActivityIndicator size='large' color={Colors.primary} />
  }

  renderContent = () => {
    const { title, showClose, content, icon } = this.props
    const isPrimitive = content !== Object(content)
    return (
      <View style={{ flex: 1, width: '100%', height: '100%', paddingTop: 8 }}>
        {icon && <View style={{ position: 'absolute' }}>{icon}</View>}
        {title && (
          <View style={style.titleContainer}>
            <Text style={style.title}>
              <b>{title}</b>
            </Text>
          </View>
        )}
        <TouchableOpacity
          onPress={this.onClose}
          style={style.closeButtonContainer}
          data-test='modal-close-button'
        >
          {showClose && (
            <View style={style.closeIconContainer}>
              <Icon name='close' color={Colors.secondary} size={32} />
            </View>
          )}
        </TouchableOpacity>
        {isPrimitive ? <Text>{content}</Text> : content}
      </View>
    )
  }

  render () {
    const { isLoading } = this.props

    return (
      <View style={{ width: '100%', height: '100%' }}>
        <TouchableWithoutFeedback onPress={this.onClose}>
          <View style={[style.backdrop, this.props.backdropStyle]} />
        </TouchableWithoutFeedback>
        <View
          style={[style.container, this.props.containerStyle]}
          pointerEvents='box-none'
        >
          <View style={[style.contentContainer, this.props.style]}>
            {isLoading ? this.renderLoading() : this.renderContent()}
          </View>
        </View>
      </View>
    )
  }
}

Simple.propType = {
  title: PropTypes.string,
  icon: PropTypes.element,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  couldClose: PropTypes.bool,
  style: ViewPropTypes.style,
  containerStyle: ViewPropTypes.style,
  backdropStyle: ViewPropTypes.style
}

Simple.defaultProps = {
  title: undefined,
  icon: undefined,
  isLoading: false,
  onClose: () => {},
  couldClose: true,
  showClose: true
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Simple)
