import { createSelector } from 'reselect'
import moment from 'moment'

const getClientsList = state => state.clients.clientsList
const getClientsListNameFilter = state => state.settings.clientsListNameFilter
const getClientsListStateFilter = state => state.settings.clientsListStateFilter
export const filteredClientsList = createSelector(
  [getClientsList, getClientsListNameFilter, getClientsListStateFilter],
  (clientsList, clientsListNameFilter, clientsListStateFilter) => {
    return clientsList
      .filter(client => client.status === clientsListStateFilter) // [TODO] When backend has state, include this filter
      .filter(
        client =>
          client.name
            .toUpperCase()
            .indexOf(clientsListNameFilter.toUpperCase()) > -1
      )
      .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
  }
)

const getSelectedClientId = state => state.clients.selectedClientId
export const selectedClient = createSelector(
  [getClientsList, getSelectedClientId],
  (clientsList, selectedClientId) => {
    const client =
      selectedClientId && clientsList.find(({ id }) => id === selectedClientId)
    return client
  }
)
const getAchievementsList = state => state.user.achievementsList
export const getPendingAchievements = createSelector(
  [getAchievementsList],
  achievementsList => {
    return achievementsList.filter(({ status }) => status === 'doing')
  }
)

const getClientsToShowUnregisteredAlert = state =>
  state.clients.clientsToShowUnregisteredAlert
export const isClientUnregistered = createSelector(
  [getClientsToShowUnregisteredAlert, getSelectedClientId],
  (clientsToShowUnregisteredAlert, selectedClientId) => {
    const client =
      selectedClientId &&
      clientsToShowUnregisteredAlert.find(id => id === selectedClientId)
    return client
  }
)

const getAgendaEvents = state =>
  state.user.user && state.user.user.consultantAgendaEvents
export const populatedAgendaEvents = createSelector(
  [getClientsList, getAgendaEvents],
  (clientsList, agendaEvents) => {
    if (!agendaEvents) return []
    const agendaEventsAux = agendaEvents.map(agendaEvent => {
      return {
        event: agendaEvent,
        client: clientsList.find(({ id }) => id === agendaEvent.userId)
      }
    })
    // group scheduled events by date
    return agendaEventsAux.reduce((group, currentEvent) => {
      const date = currentEvent.event.time.split('T')[0]
      group[date] = [...(group[date] || []), currentEvent]
      return group
    }, {})
  }
)

const getMoodboardUploadingQueue = state =>
  state.settings.moodboardUploadingQueue
export const isSelectedClientUploadingMoodboard = createSelector(
  [getMoodboardUploadingQueue, getSelectedClientId],
  (moodboardUploadingQueue, selectedClientId) => {
    return moodboardUploadingQueue.some(userId => userId === selectedClientId)
  }
)

const getPersonalColoringUploadingQueue = state =>
  state.settings.personalColoringUploadingQueue
export const isSelectedClientUploadingPersonalColoring = createSelector(
  [getPersonalColoringUploadingQueue, getSelectedClientId],
  (personalColoringUploadingQueue, selectedClientId) => {
    return personalColoringUploadingQueue.some(
      userId => userId === selectedClientId
    )
  }
)

const getProfileImageUploadingQueue = state =>
  state.settings.profileImageUploadingQueue
export const isSelectedClientUploadingProfileImage = createSelector(
  [getProfileImageUploadingQueue, getSelectedClientId],
  (profileImageUploadingQueue, selectedClientId) => {
    return profileImageUploadingQueue.some(
      userId => userId === selectedClientId
    )
  }
)

const getCurrentPlan = state => state.user && state.user.plan
export const currentPlanCustomerLimit = createSelector(
  [getCurrentPlan],
  currentPlan => {
    const customerLimit = currentPlan.feature_customerLimit
    return parseInt(customerLimit, 10) // null will be considered as infinite number of clients
  }
)

const getAmountDays = state => state.scheduling.daysAndHours
export const amountDays = createSelector([getAmountDays], daysAndHours => {
  return daysAndHours.reduce(
    (amount, answer) => (answer.isSelected === true ? amount + 1 : amount),
    0
  )
})

export const nextBirthdays = createSelector([getClientsList], clientsList => {
  const today = moment()
  return clientsList
    .map(client => {
      // Sets an attribute to be the anniversary date on this year (To ease birthdate comparison)
      return {
        ...client,
        birthMomentObject: moment.utc(client.birth).year(today.year())
      }
    })
    .filter(client => {
      // Filters birthdays on this week only
      return client.birthMomentObject.isBetween(
        today,
        today.clone().add(7, 'day'),
        'day',
        '[)'
      )
    })
    .sort((a, b) => a.birthMomentObject.diff(b.birthMomentObject))
})
