import { Component } from 'react'
import { Dimensions } from 'react-native'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SettingsActions from '../../Redux/SettingsRedux'

export const resolveDevice = ({ maxMobileWidth }) => {
  return Dimensions.get('window').width > maxMobileWidth ? 'desktop' : 'mobile'
}

export const resolveOrientation = () => {
  const { width, height } = Dimensions.get('window')
  return height > width ? 'portrait' : 'landscape'
}

class DeviceScreenListener extends Component {
  componentDidMount () {
    this.updateWindowDimensions()
    Dimensions.addEventListener('change', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    Dimensions.removeEventListener('change', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    const device = resolveDevice({ maxMobileWidth: this.props.maxMobileWidth })
    if (this.props.settings.device !== device) {
      this.props.setDevice(device)
    }

    const orientation = resolveOrientation({
      maxMobileWidth: this.props.maxMobileWidth
    })
    if (this.props.settings.orientation !== orientation) {
      this.props.setOrientation(orientation)
    }
  }

  render () {
    return this.props.children || null
  }
}

DeviceScreenListener.propTypes = {
  maxMobileWidth: PropTypes.number
}

DeviceScreenListener.defaultProps = {
  maxMobileWidth: 768
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  setDevice: (...params) => dispatch(SettingsActions.setDevice(...params)),
  setOrientation: (...params) =>
    dispatch(SettingsActions.setOrientation(...params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceScreenListener)
