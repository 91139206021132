import React from 'react'
import {
  StyleSheet,
  View,
  ViewPropTypes,
  TouchableOpacity,
  Text
} from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import SettingsRedux from '../../Redux/SettingsRedux'
import Colors from '../../Themes/Colors'
import PopUp from './Simple'

const styles = StyleSheet.create({
  buttonsContainer: {
    marginTop: 32,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    flex: 1,
    backgroundColor: Colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 16,
    margin: 8,
    borderRadius: 16,
    shadowColor: Colors.dark,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4
  },
  buttonText: {
    color: Colors.background,
    fontSize: 14,
    textAlign: 'center'
  }
})

class YesNo extends React.Component {
  onPressYes = event => {
    if (!this.props.isLoading) {
      this.props.onPressYes()
    }
  }

  onPressNo = event => {
    if (!this.props.isLoading) {
      this.props.onPressNo()
    }
  }

  renderContent = () => {
    const {
      content,
      styleButtonYes,
      textYes,
      styleButtonNo,
      textNo
    } = this.props
    return (
      <View style={{ flex: 1, width: '100%', height: '100%' }}>
        {content}
        <View style={styles.buttonsContainer}>
          <TouchableOpacity
            data-test='modal-button-no'
            onPress={this.onPressNo}
            style={[styles.button, styleButtonNo]}
          >
            <Text style={styles.buttonText}>{textNo}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            data-test='modal-button-yes'
            onPress={this.onPressYes}
            style={[
              styles.button,
              { backgroundColor: Colors.green },
              styleButtonYes
            ]}
          >
            <Text style={styles.buttonText}>{textYes}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  render () {
    const {
      content,
      onPressYes,
      styleButtonYes,
      textYes,
      onPressNo,
      styleButtonNo,
      textNo,
      ...props
    } = this.props
    return <PopUp {...props} content={this.renderContent()} />
  }
}

YesNo.propType = {
  styleButtonYes: ViewPropTypes.style,
  styleButtonNo: ViewPropTypes.style,
  textYes: PropTypes.string,
  textNo: PropTypes.string,
  onPressYes: PropTypes.func.isRequired,
  onPressNo: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  style: ViewPropTypes.style,
  showClose: PropTypes.bool,
  containerStyle: ViewPropTypes.style,
  backdropStyle: ViewPropTypes.style
}

YesNo.defaultProps = {
  textYes: 'Sim',
  textNo: 'Não',
  showClose: false,
  onClose: () => {},
  title: undefined
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(YesNo)
