import React from 'react'
import {
  TouchableWithoutFeedback,
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  ViewPropTypes
} from 'react-native'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SettingsRedux from '../../Redux/SettingsRedux'
import Colors from '../../Themes/Colors'

const style = StyleSheet.create({
  titleContainer: {
    marginBottom: 24,
    width: '100%',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    color: Colors.primary,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  backdrop: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.gray,
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  container: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    paddingHorizontal: 16
  },
  contentContainer: {
    maxWidth: 768,
    backgroundColor: Colors.white,
    borderRadius: 10,
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 16,

    shadowColor: Colors.gray,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4
  }
})

class Combinou extends React.Component {
  onClose = () => {
    if (!this.props.isLoading && this.props.couldClose) {
      this.props.onClose()
      this.props.closePopup()
    }
  }

  renderLoading = () => {
    return <ActivityIndicator size='large' color={Colors.primary} />
  }

  renderContent = () => {
    const { title, showClose, content, icon } = this.props
    const isPrimitive = content !== Object(content)
    return (
      <View style={{ flex: 1, width: '100%', height: '100%' }}>
        {icon && <View style={{ position: 'absolute' }}>{icon}</View>}
        {title && (
          <View style={style.titleContainer}>
            <Text style={style.title}>{title}</Text>
          </View>
        )}
        {isPrimitive ? <Text>{content}</Text> : content}
      </View>
    )
  }

  render () {
    const { isLoading } = this.props

    return (
      <View style={{ width: '100%', height: '100%' }}>
        <TouchableWithoutFeedback onPress={this.onClose}>
          <View style={[style.backdrop, this.props.backdropStyle]} />
        </TouchableWithoutFeedback>
        <View
          style={[style.container, this.props.containerStyle]}
          pointerEvents='box-none'
        >
          <View style={[style.contentContainer, this.props.style]}>
            {isLoading ? this.renderLoading() : this.renderContent()}
          </View>
        </View>
      </View>
    )
  }
}

Combinou.propType = {
  title: PropTypes.string,
  icon: PropTypes.element,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  couldClose: PropTypes.bool,
  style: ViewPropTypes.style,
  containerStyle: ViewPropTypes.style,
  backdropStyle: ViewPropTypes.style
}

Combinou.defaultProps = {
  title: undefined,
  icon: undefined,
  isLoading: false,
  onClose: () => {},
  couldClose: true,
  showClose: true
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Combinou)
