import { AsyncStorage } from 'react-native'
import { createMigrate } from 'redux-persist'

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
const REDUX_PERSIST = {
  active: true,
  reducerVersion: '1.0',
  storeConfig: {
    key: 'root',
    storage: AsyncStorage,
    // Reducer keys that you do NOT want stored to persistence here.
    blacklist: ['login', 'search', 'nav', 'settings', 'signUpFirstStepData'],
    version: 2,
    migrate: createMigrate({
      1: state => {
        /*
        This migration converts Old Mosty-Adm-Painel version to current.
        We decided to store only `user` and `token` attributes, so user session is kept
        */
        return Promise.resolve({
          user: {
            user: state && state.adminReducer && state.adminReducer.user,
            token: state && state.adminReducer && state.adminReducer.token
          }
        })
      },
      /*
        This migration prepares the store to display the Intro and initializes the state with gamification data.
        */
      2: state => {
        return Promise.resolve({
          user: {
            ...state.user,
            firstLogin: true,
            achievementsList: [],
            level: {}
          }
        })
      }
    })
  }
}

export default REDUX_PERSIST
