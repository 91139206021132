import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Toast from 'react-native-easy-toast'

import SettingsActions from '../Redux/SettingsRedux'

const defaultProps = color => ({
  position: 'bottom',
  opacity: 0.9,
  textStyle: { fontSize: 18, color: 'white' },
  style: { backgroundColor: color, position: 'fixed', margin: 16 }
})

class ToastHandler extends Component {
  render () {
    return (
      <Fragment>
        <Toast
          ref={ref =>
            this.props.setPositiveToastShow(
              ref && ref.show && ref.show.bind(ref)
            )
          }
          {...defaultProps('green')}
        />
        <Toast
          ref={ref =>
            this.props.setNegativeToastShow(
              ref && ref.show && ref.show.bind(ref)
            )
          }
          {...defaultProps('red')}
        />
        <Toast
          ref={ref =>
            this.props.setNeutralToastShow(
              ref && ref.show && ref.show.bind(ref)
            )
          }
          {...defaultProps('gray')}
        />
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setPositiveToastShow: (...params) =>
    dispatch(SettingsActions.setPositiveToastShow(...params)),
  setNegativeToastShow: (...params) =>
    dispatch(SettingsActions.setNegativeToastShow(...params)),
  setNeutralToastShow: (...params) =>
    dispatch(SettingsActions.setNeutralToastShow(...params))
})

export default connect(null, mapDispatchToProps)(ToastHandler)
