/* global __DEV__ */

import { put, all, select } from 'redux-saga/effects'
import { Platform } from 'react-native'
import validator from 'card-validator'

import API from '../Services/Api'
import SettingsTypes from '../Redux/SettingsRedux'
import PaymentTypes from '../Redux/PaymentRedux'
import AppConfig from '../Config/AppConfig'

export function * addMoipCard (user, card) {
  const expiration = card.validate.split('/')

  // Lazy import
  const { MoipCreditCard } = yield import('moip-sdk-js')

  // [TODO] add moip key
  const moipCreditCardHashQuery = MoipCreditCard.setPubKey(
    AppConfig.moipPublicKey
  ).setCreditCard({
    number: card.number,
    cvc: card.cvv,
    expirationMonth: expiration[0],
    expirationYear: expiration[1]
  })

  if (Platform.OS === 'web') {
    const { JSEncrypt } = yield import('jsencrypt')
    MoipCreditCard.setEncrypter(JSEncrypt, 'node')
  } else {
    const { RSA } = yield import('react-native-rsa-native')
    MoipCreditCard.setEncrypter(RSA, 'react-native')
  }

  const hash = yield moipCreditCardHashQuery.hash()

  const brand = validator.number(card.number).card.type

  const data = {
    fullname: user.name,
    cpf: user.cpf,
    last4: card.number.substr(card.number.length - 4),
    brand,
    hash
  }

  const [response, cards] = yield all([
    API.postCard(data),
    select(state => state.payment.cards)
  ])

  yield all([
    put(PaymentTypes.setCards([...cards, response.data])),
    put(PaymentTypes.setDefaultPaymentMethod('creditCard', response.data.id))
  ])
}

export function * addIuguCard (user, card, fullPricePlan) {
  const expiration = card.validate.split('/')
  let name = user.name.split(' ').filter(e => e !== '') // prevents user from inserting empty space into name
  const firstName = name.shift()
  const lastName = name.join(' ')

  window.Iugu.setAccountID(AppConfig.iuguAccountId)
  if (__DEV__) {
    window.Iugu.setTestMode(true)
  }
  const creditCard = window.Iugu.CreditCard(
    card.number,
    expiration[0],
    expiration[1],
    firstName,
    lastName,
    card.cvv
  )

  const iuguCreditCardHashQuery = new Promise((resolve, reject) => {
    window.Iugu.createPaymentToken(creditCard, async response => {
      if (response.errors) {
        console.error('erro: ', response.errors)
        reject(response.errors)
      } else {
        resolve(response)
      }
    })
  })

  const iuguResponse = yield iuguCreditCardHashQuery

  const data = {
    fullname: user.name,
    last4: card.number.substr(card.number.length - 4),
    brand: iuguResponse.extra_info.brand,
    hash: iuguResponse.id,
    fullPricePlan
  }

  const [response, cards] = yield all([
    API.postCardIugu(data),
    select(state => state.payment.cards)
  ])

  yield all([
    put(PaymentTypes.setCards([...cards, response.data])),
    put(PaymentTypes.setDefaultPaymentMethod('creditCard', response.data.id))
  ])
}

export function * resolveCardToAdd (
  user,
  card,
  activePaymentService,
  fullPricePlan
) {
  if (activePaymentService === 'moip') {
    yield addMoipCard(user, card)
  } else {
    yield addIuguCard(user, card, fullPricePlan)
  }
}

export function * addCard ({ user, card, paymentService, fullPricePlan }) {
  try {
    yield put(SettingsTypes.setLoading(true))
    yield resolveCardToAdd(user, card, paymentService.active, fullPricePlan)
    yield put(
      SettingsTypes.showPositiveToast('Cartão cadastrado com sucesso.', 2000)
    )
  } catch (err) {
    console.error(err)
  } finally {
    yield put(SettingsTypes.setLoading(false))
  }
}

export function * changeDefaultPaymentMethodIfNecessary ({ cards }) {
  const defaultPaymentMethod = yield select(state => state.payment.current)
  if (
    !defaultPaymentMethod.id ||
    !cards.some(item => item.id === defaultPaymentMethod.id)
  ) {
    cards.sort((a, b) => b.id - a.id) // Sort by most recent
    const lastCard = cards[0]

    if (lastCard) {
      yield put(PaymentTypes.setDefaultPaymentMethod('creditCard', lastCard.id))
    } else {
      yield put(PaymentTypes.setDefaultPaymentMethod(null, null))
    }
  }
}
