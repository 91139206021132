import React, { Component } from 'react'
import { Text, Platform } from 'react-native'
import { Provider } from 'react-redux'

import RootContainer from './RootContainer'
import createStore from '../Redux'
import PersistGateResolver from '../Redux/PersistGateResolver'
import SettingsTypes from '../Redux/SettingsRedux'
import UserTypes from '../Redux/UserRedux'
import SentryHandler from '../Services/SentryHandler'
import { create } from '../Services/Api'
import API from '../Services/Api'

import { sentryDSNWeb, sentryDSNNative } from '../Config'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      store: null,
      persistor: null
    }
  }

  async componentDidMount () {
    try {
      // create our store
      const { store, persistor } = await createStore()
      if (!store) {
        throw new Error('"store" could not be initialized')
      }
      // Configures API with new store
      this.configureApi(store)

      // Register Service Work
      this.registerServiceWorker()

      // Re-renders app with new store
      this.setState({ store, persistor })
    } catch (_) {}
  }

  renderLoading = () => {
    return <Text>Carregando...</Text>
  }

  // Responsible for initializing API
  // Needs to receive
  configureApi = store => {
    // Selects `token` from redux
    const tokenSelector = () => {
      const currentStoreState = store.getState()
      const currentToken =
        currentStoreState &&
        currentStoreState.user &&
        currentStoreState.user.token
      return currentToken
    }

    // Initializes API
    this.Api = create({
      tokenSelector,
      onBadToken: () => store.dispatch(UserTypes.logout()), // Should run logout routine!
      showNegativeToast: (...params) =>
        store.dispatch(SettingsTypes.showNegativeToast(...params))
    })
  }

  // Responsible for register service worker
  registerServiceWorker = () => {
    if (typeof navigator.serviceWorker !== 'undefined') {
      navigator.serviceWorker.register('pwabuilder-sw.js')
    }
  }

  render () {
    if (!this.state.store) return this.renderLoading()

    return (
      <SentryHandler
        dsn={Platform.OS === 'web' ? sentryDSNWeb : sentryDSNNative}
      >
        <Provider store={this.state.store}>
          <PersistGateResolver persistor={this.state.persistor}>
            <RootContainer />
          </PersistGateResolver>
        </Provider>
      </SentryHandler>
    )
  }
}

export default App
