import React from 'react'
import { View, Image, Text } from 'react-native'
import { Images } from '../Themes'

const InviteClientIcon = () => (
  <View
    style={{ display: 'inline-block', height: '100%', verticalAlign: 'middle' }}
  >
    <Image source={Images.exclamation} style={{ height: 16, width: 16 }} />
  </View>
)

export const resolvePopupHaveACustomerUsingTheApp = () => {
  return {
    type: 'simple',
    props: {
      title: 'Tenha uma cliente usando o app Mosty',
      content: (
        <View>
          <Text style={{ textAlign: 'justify' }}>
            Você sabia que sempre que cadastra uma cliente por aqui, também
            criamos uma conta para ela acessar o próprio perfil no app Mosty?
            Mas antes é necessário que você envie um convite de confirmação de
            cadastro, clicando no perfil dela, e em seguida no ícone de
            exclamação {<InviteClientIcon />}
          </Text>
        </View>
      )
    }
  }
}
