/* global __DEV__ */
import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
// import LogRocket from 'logrocket'

import Rehydration from '../Services/Rehydration'
import ReduxPersist from '../Config/ReduxPersist'

// creates the store
export default async (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = []
  const enhancers = []

  /* ------------- Saga Middleware ------------- */

  const sagaMiddleware = createSagaMiddleware({})
  middleware.push(sagaMiddleware)

  /* ------------- Redux logger Middleware ------------- */
  if (__DEV__) {
    // DEV only middleware
    middleware.push(logger)
  } else {
    // PROD only middleware
    // middleware.push(LogRocket.reduxMiddleware())
  }

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware))

  const createAppropriateStore = createStore
  const store = createAppropriateStore(rootReducer, compose(...enhancers))

  // configure persistStore and check reducer version number
  let persistor
  if (ReduxPersist.active) {
    persistor = await Rehydration.updateReducers(store)
  }

  // kick off root saga
  const sagasManager = sagaMiddleware.run(rootSaga)

  return {
    store,
    sagasManager,
    sagaMiddleware,
    persistor
  }
}
