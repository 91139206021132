import { createReducer, createActions } from 'reduxsauce'
import _ from 'lodash'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setClientsListLastFetch: ['clientsListLastFetch'],
  popClientToShowUnregisteredAlert: ['client'],
  setClientsToShowUnregisteredAlert: ['clientsToShowUnregisteredAlert'],
  setClientsList: ['clientsList'],
  setClientsListLoading: ['isClientsListLoading'],
  putClient: ['client'],
  setSelectedClientId: ['selectedClientId'],
  getSelectedClientData: [],
  setClientByIdLoading: ['isLoadingClientById'],
  setClientByIdRequestError: ['clientByIdRequestError'],
  putClientAttribute: ['clientId', 'key', 'value'],
  setClientShoppingListCategory: ['clientShoppingListCategory'],
  setClientStyles: ['clientStyles'],
  seeClient: []
})

export const ClientsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  clientsList: [],
  clientsToShowUnregisteredAlert: [],
  clientsListLastFetch: undefined,
  isClientsListLoading: false,
  selectedClientId: null,
  isLoadingClientById: false,
  clientByIdRequestError: null,
  clientShoppingListCategory: [],
  clientStyles: undefined
}

/* ------------- Reducers ------------- */

export const setClientsListLastFetch = (state, { clientsListLastFetch }) => {
  return { ...state, clientsListLastFetch }
}

export const setClientsToShowUnregisteredAlert = (
  state,
  { clientsToShowUnregisteredAlert }
) => {
  return { ...state, clientsToShowUnregisteredAlert }
}

export const popClientToShowUnregisteredAlert = (state, { client }) => {
  const index = state.clientsToShowUnregisteredAlert.indexOf(client)
  const clientsToShowUnregisteredAlert = [
    ...state.clientsToShowUnregisteredAlert
  ]

  if (index > -1) {
    clientsToShowUnregisteredAlert.splice(index, 1)
    return { ...state, clientsToShowUnregisteredAlert }
  }

  return { ...state }
}

export const setClientsList = (state, { clientsList }) => {
  return { ...state, clientsList }
}

export const setClientsListLoading = (state, { isClientsListLoading }) => {
  return { ...state, isClientsListLoading }
}

export const putClient = (state, { client }) => {
  const clientIndex = state.clientsList.findIndex(({ id }) => id === client.id)

  let clientsList = []

  if (clientIndex === -1) {
    // If client not yet on list, add it to the end
    clientsList = [...state.clientsList, client]
  } else {
    // If its already on the list, replace with new client data
    clientsList = [
      ...state.clientsList.slice(0, clientIndex),
      client,
      ...state.clientsList.slice(clientIndex + 1)
    ]
  }

  return { ...state, clientsList }
}

export const setSelectedClientId = (state, { selectedClientId }) => {
  return { ...state, selectedClientId: _.toInteger(selectedClientId) }
}

export const setClientByIdLoading = (state, { isLoadingClientById }) => {
  return { ...state, isLoadingClientById }
}

export const setClientByIdRequestError = (
  state,
  { clientByIdRequestError }
) => {
  return { ...state, clientByIdRequestError }
}

// Put's a new value in an client's attribute (Which must be an array)
export const putClientAttribute = (state, { clientId, key, value }) => {
  const clientIndex = state.clientsList.findIndex(({ id }) => id === clientId)
  // If user does not exists, it's not possible to put its attribute
  if (clientIndex === -1) {
    return state
  }

  const client = state.clientsList[clientIndex]

  const attributeIndex = client[key].findIndex(({ id }) => id === value.id)

  let newUserAttribute = []
  if (attributeIndex === -1) {
    // If client not yet on list, add it to the end
    newUserAttribute = [...client[key], value]
  } else {
    // If its already on the list, replace with new client data
    newUserAttribute = [
      ...client[key].slice(0, attributeIndex),
      value,
      ...client[key].slice(attributeIndex + 1)
    ]
  }

  // Compute new client object
  const newClient = {
    ...state.clientsList[clientIndex],
    [key]: newUserAttribute
  }

  // Insert into clients list
  const clientsList = [
    ...state.clientsList.slice(0, clientIndex),
    newClient,
    ...state.clientsList.slice(clientIndex + 1)
  ]

  return { ...state, clientsList }
}

export const setClientShoppingListCategory = (
  state,
  { clientShoppingListCategory }
) => {
  return { ...state, clientShoppingListCategory }
}

export const setClientStyles = (state, { clientStyles }) => {
  return { ...state, clientStyles }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CLIENTS_LIST_LAST_FETCH]: setClientsListLastFetch,
  [Types.SET_CLIENTS_TO_SHOW_UNREGISTERED_ALERT]: setClientsToShowUnregisteredAlert,
  [Types.POP_CLIENT_TO_SHOW_UNREGISTERED_ALERT]: popClientToShowUnregisteredAlert,
  [Types.SET_CLIENTS_LIST]: setClientsList,
  [Types.SET_CLIENTS_LIST_LOADING]: setClientsListLoading,
  [Types.PUT_CLIENT]: putClient,
  [Types.SET_SELECTED_CLIENT_ID]: setSelectedClientId,
  [Types.SET_CLIENT_BY_ID_LOADING]: setClientByIdLoading,
  [Types.SET_CLIENT_BY_ID_REQUEST_ERROR]: setClientByIdRequestError,
  [Types.PUT_CLIENT_ATTRIBUTE]: putClientAttribute,
  [Types.SET_CLIENT_SHOPPING_LIST_CATEGORY]: setClientShoppingListCategory,
  [Types.SET_CLIENT_STYLES]: setClientStyles
})
