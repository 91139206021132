import React from 'react'
import {
  StyleSheet,
  View,
  ViewPropTypes,
  TouchableOpacity,
  Text,
  TextInput
} from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import SettingsRedux from '../../Redux/SettingsRedux'
import Colors from '../../Themes/Colors'
import PopUp from './Simple'

const styles = StyleSheet.create({
  buttonsContainer: {
    marginTop: 32,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    borderStyle: 'solid',
    borderTopWidth: 1,
    borderColor: Colors.gray
  },
  button: {
    flex: 1,
    backgroundColor: Colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 16,
    margin: 16,

    borderRadius: 10,

    shadowColor: Colors.dark,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.7,
    shadowRadius: 4
  },
  buttonText: {
    color: Colors.background,
    fontSize: 14,
    textAlign: 'center'
  },
  inputConfirmationContainer: {
    width: '60%',
    height: 32,
    marginHorizontal: 32,
    marginTop: 16,
    paddingLeft: 16,
    paddingRight: 8,
    borderWidth: 1,
    borderRadius: 16,
    borderColor: Colors.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  confirmationTextInput: {
    width: '100%'
  },
  disabledButton: {
    backgroundColor: Colors.gray
  }
})

class YesNoWithInputConfirmation extends React.Component {
  constructor (props) {
    super(props)
    this.state = { disabledYesButton: true }
  }

  onPressYes = event => {
    if (!this.props.isLoading) {
      this.props.onPressYes()
    }
  }

  onPressNo = event => {
    if (!this.props.isLoading) {
      this.props.onPressNo()
    }
  }

  checkInputConfirmation = text => {
    if (this.props.confirmationWord === text) {
      this.setState({ disabledYesButton: false })
    }
  }

  renderContent = () => {
    const {
      content,
      styleButtonYes,
      textYes,
      styleButtonNo,
      textNo,
      descriptionInput
    } = this.props
    return (
      <View style={{ flex: 1, width: '100%', height: '100%' }}>
        {content}
        <View style={{ alignItems: 'center' }}>
          <View style={styles.inputConfirmationContainer}>
            <TextInput
              style={styles.confirmationTextInput}
              onChangeText={text => this.checkInputConfirmation(text)}
            />
          </View>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 14,
              marginTop: 8,
              fontStyle: 'italic'
            }}
          >
            {descriptionInput}
          </Text>
        </View>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity
            data-test='modal-button-no'
            onPress={this.onPressNo}
            style={[styles.button, styleButtonNo]}
          >
            <Text style={styles.buttonText}>{textNo}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            data-test='modal-button-yes'
            onPress={this.onPressYes}
            style={[
              styles.button,
              { backgroundColor: Colors.green },
              this.state.disabledYesButton
                ? styles.disabledButton
                : styleButtonYes
            ]}
            disabled={this.state.disabledYesButton}
          >
            <Text style={styles.buttonText}>{textYes}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  render () {
    const {
      content,
      onPressYes,
      styleButtonYes,
      textYes,
      onPressNo,
      styleButtonNo,
      textNo,
      descriptionInput,
      ...props
    } = this.props
    return <PopUp {...props} content={this.renderContent()} />
  }
}

YesNoWithInputConfirmation.propType = {
  styleButtonYes: ViewPropTypes.style,
  styleButtonNo: ViewPropTypes.style,
  textYes: PropTypes.string,
  textNo: PropTypes.string,
  onPressYes: PropTypes.func.isRequired,
  onPressNo: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  style: ViewPropTypes.style,
  showClose: PropTypes.bool,
  containerStyle: ViewPropTypes.style,
  backdropStyle: ViewPropTypes.style
}

YesNoWithInputConfirmation.defaultProps = {
  textYes: 'Sim',
  textNo: 'Não',
  showClose: false,
  onClose: () => {},
  title: undefined
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(YesNoWithInputConfirmation)
