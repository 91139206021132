import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setDaysAndHours: ['day'],
  setFirstDay: ['day'],
  setNextDay: [],
  setPreviousDay: [],
  setHours: ['hour'],
  cleanHoursUnselected: [],
  cleanDaysAndHours: [],
  setCurrentPageResult: ['page']
})

export const SchedulingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  // saves all days and times, both selected and unselected
  daysAndHours: [],
  currentDay: {},
  currentPageResult: 'horários'
}

/* ------------- Reducers ------------- */

export const setDaysAndHours = (state, { day }) => {
  return { ...state, daysAndHours: day }
}

export const setFirstDay = (state, { day }) => {
  const { daysAndHours } = state
  const indexCurrentDay = daysAndHours.findIndex(answer => answer.label === day)
  const currentDay = { label: day, id: indexCurrentDay }
  return { ...state, currentDay }
}

export const setNextDay = state => {
  const { daysAndHours, currentDay } = state
  const { id } = currentDay

  // it is necessary to go through the whole daysAndHours object, because the next selected day is the one with the attribute isSelected === true, it does not necessarily have to be the next day of the week (id + 1). For example, if the customer selects Monday and Wednesday, Tuesday is not the next day (id + 1), but Wednesday
  for (let i = id + 1; i < daysAndHours.length; i++) {
    if (daysAndHours[i].isSelected === true) {
      const currentDay = { label: daysAndHours[i].label, id: i }
      return { ...state, currentDay }
    }
  }
  return { ...state }
}

export const setPreviousDay = state => {
  const { daysAndHours, currentDay } = state
  const { id } = currentDay

  // same thing as setNextDay. For example, if the customer selects Monday and Wednesday, Tuesday is not the previous day (id-1), but Monday. So it is also necessary to scan the current point to the beginning of the object
  for (let i = id - 1; i > -1; i--) {
    if (daysAndHours[i].isSelected === true) {
      const currentDay = { label: daysAndHours[i].label, id: i }
      return { ...state, currentDay }
    }
  }
  return { ...state }
}

// updates the time of daysAndHours according to the currentDay
export const setHours = (state, { hour }) => {
  const { currentDay } = state
  const { id } = currentDay
  let { daysAndHours } = state

  daysAndHours = daysAndHours.map((answer, index) => {
    if (index === id) {
      return { ...answer, hours: hour }
    }
    return { ...answer }
  })

  return { ...state, daysAndHours }
}

// clears the daysAndHours schedules for days that are no longer selected
export const cleanHoursUnselected = (state, {}) => {
  let { daysAndHours } = state

  daysAndHours = daysAndHours.map(answer => {
    if (answer.isSelected === false) {
      let hoursArray = []
      answer.hours.map(hour => {
        hoursArray.push({ ...hour, isSelected: false, isStuck: false })
      })
      return { ...answer, hours: hoursArray }
    }
    return { ...answer }
  })
  return { ...state, daysAndHours }
}

export const cleanDaysAndHours = (state, {}) => {
  return { ...state, daysAndHours: [] }
}

export const setCurrentPageResult = (state, { page }) => {
  return { ...state, currentPageResult: page }
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_DAYS_AND_HOURS]: setDaysAndHours,
  [Types.SET_FIRST_DAY]: setFirstDay,
  [Types.SET_NEXT_DAY]: setNextDay,
  [Types.SET_PREVIOUS_DAY]: setPreviousDay,
  [Types.SET_HOURS]: setHours,
  [Types.CLEAN_HOURS_UNSELECTED]: cleanHoursUnselected,
  [Types.CLEAN_DAYS_AND_HOURS]: cleanDaysAndHours,
  [Types.SET_CURRENT_PAGE_RESULT]: setCurrentPageResult
})
