import ReduxPersist from '../Config/ReduxPersist'
import { AsyncStorage } from 'react-native'
import { persistStore } from 'redux-persist'

const updateReducers = async store => {
  const reducerVersion = ReduxPersist.reducerVersion

  // Check to ensure latest reducer version
  const localVersion = await AsyncStorage.getItem('reducerVersion')

  let persistor = null
  await new Promise((resolve, reject) => {
    try {
      if (localVersion !== reducerVersion) {
        // Purge store
        persistor = persistStore(store, null, () => resolve(store)).purge()
        AsyncStorage.setItem('reducerVersion', reducerVersion)
      } else {
        persistor = persistStore(store, null, () => resolve(store))
      }
    } catch (err) {
      persistor = persistStore(store, null, () => resolve(store))
      AsyncStorage.setItem('reducerVersion', reducerVersion)
    }
  })

  return persistor
}

export default { updateReducers }
