// leave off @2x/@3x
const gifs = {
  virtualCloset: require('../Gifs/virtualCloset.gif'),
  shopList: require('../Gifs/shopList.gif'),
  personalColoring: require('../Gifs/personalColoring.gif'),
  billing: require('../Gifs/billing.gif'),
  styleGuide: require('../Gifs/styleGuide.gif')
}

export default gifs
