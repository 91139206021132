import React from 'react'
import {
  TouchableWithoutFeedback,
  StyleSheet,
  View,
  Text,
  Image,
  Animated
} from 'react-native'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SettingsRedux from '../../Redux/SettingsRedux'
import Colors from '../../Themes/Colors'
import Images from '../../Themes/Images'

import { openMaps, openWaze } from '../../Services/Helpers'

let animatedValue = new Animated.Value(0)
const setTime = toValue =>
  setTimeout(() =>
    Animated.timing(animatedValue, {
      toValue: toValue,
      duration: 200,
      useNativeDriver: true
    }).start()
  )

const maps = [
  {
    label: 'Maps',
    image: Images.googleMaps,
    onClick: address => openMaps(address)
  },
  {
    label: 'Waze',
    image: Images.waze,
    onClick: address => openWaze(address.replace(/ /g, '%20'))
  }
]

const style = StyleSheet.create({
  titleContainer: {
    marginBottom: 24,
    width: '100%',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold'
  },
  description: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 8
  },
  backdrop: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.gray,
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  container: {
    position: 'fixed',
    bottom: -300,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
    zIndex: 11,
    paddingHorizontal: 8,
    marginBottom: 8
  },
  contentContainer: {
    maxWidth: 768,
    backgroundColor: Colors.white,
    borderRadius: 24,
    width: '100%',
    paddingVertical: 16
  }
})

class MapOptions extends React.Component {
  componentDidMount () {
    setTime(-300)
  }

  onClose = () => {
    setTime(300)
    this.props.closePopup()
  }

  renderContent = () => {
    const { address } = this.props

    return (
      <>
        <View style={{ flex: 1, width: '100%', height: '100%', marginTop: 8 }}>
          <View style={style.titleContainer}>
            <Text style={style.title}>Abrir com</Text>
          </View>
          <View
            style={{
              flex: 1,
              width: '100%',
              height: '100%',
              flexDirection: 'row'
            }}
          >
            {maps.map((map, index) => (
              <View
                key={index}
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  marginTop: 8
                }}
                onClick={() => map.onClick(address)}
              >
                <Image source={map.image} style={{ height: 48, width: 48 }} />
                <Text style={style.description}>{map.label}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ width: '100%', marginTop: 36 }}>
          <View
            style={{
              width: '100%',
              borderTopWidth: 1,
              borderColor: Colors.gray,
              opacity: 0.2
            }}
          />
          <Text
            style={[style.description, { paddingTop: 8 }]}
            onClick={this.onClose}
          >
            Cancelar
          </Text>
        </View>
      </>
    )
  }

  render () {
    return (
      <View style={{ width: '100%', height: '100%' }}>
        <TouchableWithoutFeedback onPress={this.onClose}>
          <View style={[style.backdrop]} />
        </TouchableWithoutFeedback>
        <Animated.View
          style={[
            style.container,
            { transform: [{ translateY: animatedValue }] }
          ]}
          pointerEvents='box-none'
        >
          <View style={[style.contentContainer]}>{this.renderContent()}</View>
        </Animated.View>
      </View>
    )
  }
}

MapOptions.propType = {
  address: PropTypes.string
}

MapOptions.defaultProps = {
  address: undefined
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(MapOptions)
