import { createReducer, createActions } from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setClientData: ['clientData'],
  setDueDate: ['dueDate'],
  setMaxInstallments: ['maxInstallments'],
  clearFields: []
})

export const ServiceBillingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  clientData: null,
  dueDate: '',
  maxInstallments: '1'
}

/* ------------- Reducers ------------- */

export const setClientData = (state, { clientData }) => {
  return { ...state, clientData }
}

export const setDueDate = (state, { dueDate }) => {
  return { ...state, dueDate }
}

export const setMaxInstallments = (state, { maxInstallments }) => {
  return { ...state, maxInstallments }
}

export const clearFields = state => {
  return INITIAL_STATE
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CLIENT_DATA]: setClientData,
  [Types.SET_DUE_DATE]: setDueDate,
  [Types.SET_MAX_INSTALLMENTS]: setMaxInstallments,
  [Types.CLEAR_FIELDS]: clearFields
})
