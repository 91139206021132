import { all, put, select, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import moment from 'moment'
import ClientsActions, { ClientsTypes } from '../Redux/ClientsRedux'
import API from '../Services/Api'
import { selectedClient } from '../Redux/selectors'
import SettingsActions from '../Redux/SettingsRedux'

export function * getSelectedClientData () {
  yield put(ClientsActions.setClientByIdLoading(true))
  try {
    const selectedClientId = yield select(
      state => state.clients.selectedClientId
    )
    if (selectedClientId == null) {
      // client ID not found!
      throw new Error('Selected client ID must be defined!')
    }

    const selectedClientData = yield select(selectedClient)
    if (!selectedClientData) {
      // User data not found!
      // Fetch from API
      const res = yield API.getClientById(selectedClientId)
      const clientData = res.data
      yield put(ClientsActions.putClient(clientData))
    } else {
      // User already on redux. Should re-fetch to improve data consistency?
    }

    yield put(ClientsActions.setClientByIdRequestError(null))
  } catch (err) {
    yield put(ClientsActions.setClientByIdRequestError(err))
  } finally {
    yield put(ClientsActions.setClientByIdLoading(false))
  }
}

/*
GAMBIARRA
monitorMoodboardUploadingQueue if responsible for monitoring every client put on redux,
and if it's ID is stored on moodboardUploadingQueue, and it's current moodboard is valid,
remove from that queue
This is important to show MoodboardSuccessView to user, when moodboard is being uploaded
*/
export function * monitorMoodboardUploadingQueue () {
  yield all([
    takeEvery(ClientsTypes.SET_CLIENTS_LIST, checkMoodboardUploadingStatus),
    takeEvery(ClientsTypes.PUT_CLIENT, ({ client }) =>
      checkMoodboardUploadingStatus({ clientsList: [client] })
    )
  ])
}

function * checkMoodboardUploadingStatus ({ clientsList }) {
  const clientsWithMoodboard = clientsList.filter(
    client => client?.moodboard?.length > 0
  )
  const idsWithMoodboard = clientsWithMoodboard.map(({ id }) => id)

  const moodboardUploadingQueue = yield select(
    state => state.settings.moodboardUploadingQueue
  )

  const idsToUpdateMoodboardQueueStatus = _.intersection(
    idsWithMoodboard,
    moodboardUploadingQueue
  )

  yield put(
    SettingsActions.popFromMoodboardUploadingQueue(
      idsToUpdateMoodboardQueueStatus
    )
  )
}

export function * monitorPersonalColoringUploadingQueue () {
  yield all([
    takeEvery(
      ClientsTypes.SET_CLIENTS_LIST,
      checkPersonalColoringUploadingStatus
    ),
    takeEvery(ClientsTypes.PUT_CLIENT, ({ client }) =>
      checkPersonalColoringUploadingStatus({ clientsList: [client] })
    )
  ])
}

function * checkPersonalColoringUploadingStatus ({ clientsList }) {
  const clientsWithPersonalColoring = clientsList.filter(
    client => client?.personalColoringImages?.length > 0
  )
  const idsWithPersonalColoring = clientsWithPersonalColoring.map(
    ({ id }) => id
  )

  const personalColoringUploadingQueue = yield select(
    state => state.settings.personalColoringUploadingQueue
  )

  const idsToUpdatePersonalColoringQueueStatus = _.intersection(
    idsWithPersonalColoring,
    personalColoringUploadingQueue
  )

  yield put(
    SettingsActions.popFromPersonalColoringUploadingQueue(
      idsToUpdatePersonalColoringQueueStatus
    )
  )
}

export function * monitorProfileImageUploadingQueue () {
  yield all([
    takeEvery(ClientsTypes.SET_CLIENTS_LIST, checkProfileImageUploadingStatus),
    takeEvery(ClientsTypes.PUT_CLIENT, ({ client }) =>
      checkProfileImageUploadingStatus({ clientsList: [client] })
    )
  ])
}

function * checkProfileImageUploadingStatus ({ clientsList }) {
  const clientsWithProfileImage = clientsList.filter(
    client => client?.profileImage?.length > 0
  )

  const idsWithProfileImage = clientsWithProfileImage.map(({ id }) => id)

  const profileImageUploadingQueue = yield select(
    state => state.settings.profileImageUploadingQueue
  )

  const idsToUpdateProfileImageQueueStatus = _.intersection(
    idsWithProfileImage,
    profileImageUploadingQueue
  )

  yield put(
    SettingsActions.popFromProfileImageUploadingQueue(
      idsToUpdateProfileImageQueueStatus
    )
  )
}

export function * setClientsToShowUnregisteredAlert ({ clientsList }) {
  const AUTH_OF_UNREGISTERED = 5
  const INTERVAL = 24
  const clientsListLastFetch = moment(
    (yield select(state => state.clients.clientsListLastFetch)) || 0
  )
  const current = moment()

  if (current.diff(clientsListLastFetch, 'hours') >= INTERVAL) {
    const clientsToShowUnregisteredAlert = clientsList
      .filter(client => client.auth === AUTH_OF_UNREGISTERED)
      .map(({ id }) => id)

    yield put(
      ClientsActions.setClientsToShowUnregisteredAlert(
        clientsToShowUnregisteredAlert
      )
    )
    yield put(ClientsActions.setClientsListLastFetch(current.valueOf()))
  }
}
