import React from 'react'
import { Text, StyleSheet } from 'react-native'
import Fonts from '../Themes/Fonts'

export default class CustomText extends React.Component {
  render () {
    return (
      <Text {...this.props} style={[styles.defaultStyle, this.props.style]}>
        {this.props.children}
      </Text>
    )
  }
}

const styles = StyleSheet.create({
  defaultStyle: {
    fontFamily: Fonts.type.base
  }
})
