import React, { Component } from 'react'
import { Colors, Images } from '../Themes'
import { Animated, StyleSheet, View, Image } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'

class Skeletons extends Component {
  constructor (props) {
    super(props)

    this.state = {
      x: new Animated.Value(0)
    }
  }

  componentDidMount () {
    this.skeletonAnimation()
  }

  skeletonAnimation = () => {
    Animated.loop(
      Animated.timing(this.state.x, {
        toValue: 1,
        duration: 1500
      })
    ).start()
  }

  linearGradient = isSmallCard => {
    const translateXSmallCard = this.state.x.interpolate({
      inputRange: [0, 1],
      outputRange: [-80, 100]
    })

    const translateX = this.state.x.interpolate({
      inputRange: [0, 1],
      outputRange: [-300, 400]
    })

    return (
      <Animated.View
        style={{
          height: '100%',
          width: '100%',
          transform: [
            { translateX: isSmallCard ? translateXSmallCard : translateX }
          ]
        }}
      >
        <LinearGradient
          style={{
            height: '100%',
            width: '60%'
          }}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 1 }}
          colors={['#DEDEDE', '#DDDDDD', '#E5E5E5', '#DEDEDE']}
        />
      </Animated.View>
    )
  }

  singleComponentSkeleton = () => {
    return (
      <View
        style={[
          this.props.style,
          { backgroundColor: Colors.graySkeleton, overflow: 'hidden' }
        ]}
      >
        {this.linearGradient(this.props.isSmallCard)}
      </View>
    )
  }

  generalLoadingSkeleton = () => {
    return (
      <View style={styles.container}>
        <View style={styles.headerCointainer}>
          <Image source={Images.logo} style={styles.logoImage} />
        </View>
        <View style={styles.innerContainer}>
          <View style={[styles.sectionContainer]}>
            <View style={styles.cardGeneralLoading}>
              {this.linearGradient()}
            </View>
            <View style={styles.cardGeneralLoading}>
              {this.linearGradient()}
            </View>
            <View style={styles.cardGeneralLoading}>
              {this.linearGradient()}
            </View>
            <View style={styles.cardGeneralLoading}>
              {this.linearGradient()}
            </View>
            <View style={styles.cardGeneralLoading}>
              {this.linearGradient()}
            </View>
          </View>
        </View>
      </View>
    )
  }

  render () {
    // If pass the type property as 'singleComponentLoading' , a single loading view will be returned. It is worth mentioning that it is important to define the width and height of the component.
    // If this property does not exist, it calls the general loading skeleton.
    return this.props.type === 'singleComponentLoading'
      ? this.singleComponentSkeleton()
      : this.generalLoadingSkeleton()
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    backgroundColor: Colors.startBackgroundColor
  },
  innerContainer: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
    marginHorizontal: 'auto',
    maxWidth: 480
  },
  sectionContainer: {
    width: '100%',
    marginTop: 32,
    paddingHorizontal: 24,
    paddingTop: 16
  },
  cardGeneralLoading: {
    width: '100%',
    height: 129,
    backgroundColor: Colors.graySkeleton,
    borderRadius: 16,
    marginBottom: 16,
    overflow: 'hidden'
  },
  animatedShortcut: {
    width: '30%',
    height: '100%',
    backgroundColor: '#E5E5E5'
  },
  headerCointainer: {
    width: '100%',
    height: 78,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.grayLight,
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    shadowColor: '#00000033',
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 16
  },
  logoImage: {
    width: 132,
    height: 25
  }
})

export default Skeletons
