/* globals __DEV__ */
import { Route, Redirect, Switch, Router } from 'react-router'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'

import { createBrowserHistory } from 'history'
import { View } from 'react-native'

const history = createBrowserHistory()

// if (!__DEV__) {
//   // Sets Google Analytics pageview listener
//   history.listen(location => {
//     ReactGA.set({ page: location.pathname })
//     ReactGA.pageview(location.pathname)
//   })
// }

const RouterGeneratorWeb = ({ routeMap, children }) => {
  useEffect(() => {
    if (!__DEV__) {
      // Sets Google Analytics pageview listener
      history.listen(location => {
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
      })
    }
  })
  const GeneratedRouter = Object.values(routeMap).map(currentRoute => {
    const Component = currentRoute.component
    const { seo } = currentRoute

    return (
      <Route
        key={currentRoute.path}
        path={currentRoute.path}
        exact={currentRoute.exact}
        render={props => (
          <View style={{ flex: 1, width: '100%', height: '100%' }}>
            {seo && (
              <Helmet>
                {seo.title && <title>{seo.title}</title>}
                {seo.description && (
                  <meta name='description' content={seo.description} />
                )}
                {seo.keywords && (
                  <meta name='keywords' content={seo.keywords} />
                )}
                {seo.robots && <meta name='robots' content={seo.robots} />}
              </Helmet>
            )}
            <Component params={props} />
          </View>
        )}
      />
    )
  })

  // Adds a NotFound redirect to index
  GeneratedRouter.push(<Redirect key='404' from='*' to='/' />)

  return (
    <Router history={history}>
      <View style={{ flex: 1, width: '100%', height: '100%' }}>
        <Switch>{GeneratedRouter}</Switch>
        {children}
      </View>
    </Router>
  )
}

RouterGeneratorWeb.propTypes = {
  routeMap: PropTypes.object
}

export default RouterGeneratorWeb
