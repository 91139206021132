/* global __DEV__ */
import { Component } from 'react'
import * as Sentry from '@sentry/browser'
import util from 'util'

/*
Helper to intercept some console methods, and send them to Sentry.
It also may disable some console methods consoling for production, if desired
*/
const interceptConsole = (methods = [{ method: 'error', devOnly: true }]) => {
  methods.forEach(({ method, level, devOnly }) => {
    if (!console[method]) return // Invalid method

    const oldConsoleMethod = console[method]
    console[method] = (...params) => {
      if (!devOnly || (devOnly && __DEV__)) {
        // Check if should console or block
        oldConsoleMethod(...params)
      }

      // Maps the input array elements (objects and arrays) to string
      const inspectedParams = params.map(param => {
        if (typeof param === 'object') {
          return util.inspect(param, { showHidden: false, depth: null })
        } else {
          return param
        }
      })

      // If its an internal Sentry Logger error, simply finish procedure here
      // This avoid infinity loops of errors
      if (inspectedParams[0].includes('Sentry Logger')) {
        return
      }

      Sentry.withScope(scope => {
        scope.setLevel(level)
        Sentry.captureMessage(inspectedParams.join(' '))
      })
    }
  })
}

/*
Defined that:
console.error, and console.warn will be sent to sentry (And not written to local stream),
console.info will be sent without local restrictions.
Other consoles (Like console.log) won't be modified
*/
interceptConsole([
  { method: 'info', level: 'debug', devOnly: false },
  { method: 'warn', level: 'warning', devOnly: true },
  { method: 'error', level: 'error', devOnly: true }
])

class SentryHandler extends Component {
  constructor (props) {
    super(props)

    const { name, version } = require('../../package.json')
    if (!__DEV__) {
      Sentry.init({
        dsn: props.dsn,
        release: `${name}@${version}`,
        environment: process.env.REACT_APP_ENV,
        debug: __DEV__,
        attachStacktrace: true
      })
    }
  }

  componentDidCatch (error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setTag('type', 'root')
      scope.setLevel(Sentry.Severity.Error)
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render () {
    return this.props.children
  }
}

export default SentryHandler
