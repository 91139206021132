import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import API from '../../Services/Api'

import SettingsActions from '../../Redux/SettingsRedux'
import UserTypes from '../../Redux/UserRedux'
import PaymentTypes from '../../Redux/PaymentRedux'
import ClientsTypes from '../../Redux/ClientsRedux'
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import { Colors } from '../../Themes'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    flex: 1,
    marginTop: 4,
    paddingVertical: 4,
    paddingHorizontal: 32,
    borderRadius: 16,
    backgroundColor: Colors.primary,
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonLabel: {
    fontSize: 14,
    color: Colors.white
  },
  buttonContainer: {
    marginTop: 12,
    maxWidth: 512
  }
})

export const requiresAuthentication = MyComponent => {
  class AuthenticationInterface extends Component {
    state = {
      isLoading: true
    }

    componentDidMount () {
      const fetchData = async () => {
        if (this.props.user.token) {
          if (!this.props.user.user) {
            try {
              const results = await Promise.all([
                API.getMe(),
                API.getConsultantProfile(),
                API.listCards(),
                API.getAchievementsList(),
                API.getUserLevel(),
                API.getClientsList()
              ])
              const [
                user,
                consultantProfile,
                cards,
                achievementsList,
                userLevel,
                clientList
              ] = results

              this.props.setSignUpFirstStepData(null)
              this.props.setUser(user.data)
              this.props.setConsultantProfile(consultantProfile.data)
              this.props.setAchievementsList(achievementsList.data.results)
              this.props.setCards(cards.data)
              this.props.setLevel(userLevel.data)
              this.props.setClientsList(clientList.data)
              this.setState({ isLoading: false })
            } catch (error) {
              console.error('Failed to fetch user:', error)
              this.setState({ isLoading: false })
            }
          } else {
            this.setState({ isLoading: false })
          }
        } else {
          this.setState({ isLoading: false })
        }
      }

      fetchData()
    }

    render () {
      const { isLoading } = this.state

      if (isLoading) {
        return (
          <View
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: Colors.white
            }}
          >
            <ActivityIndicator size={32} color={Colors.primary} />
          </View>
        )
      }

      if (!this.props.user.token || !this.props.user.user) {
        this.props.setRedirectPushParams(this.props.location)
        return <Redirect to='/entrar' />
      } else if (this.props.redirectPushParams) {
        // Usuário logado e há um redirecionamento pendente
        const redirectUrl = this.props.redirectPushParams
        this.props.setRedirectPushParams(null)
        return <Redirect to={redirectUrl} />
      } else {
        return <MyComponent {...this.props} />
      }
    }
  }

  const mapStateToProps = state => ({
    user: state.user,
    redirectPushParams: state.settings.redirectPushParams
  })

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        setRedirectPushParams: SettingsActions.setRedirectPushParams,
        setSignUpFirstStepData: SettingsActions.setSignUpFirstStepData,
        setUser: UserTypes.setUser,
        setConsultantProfile: UserTypes.setConsultantProfile,
        setAchievementsList: UserTypes.setAchievementsList,
        setLevel: UserTypes.setLevel,
        setCards: PaymentTypes.setCards,
        setClientsList: ClientsTypes.setClientsList
      },
      dispatch
    )

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AuthenticationInterface))
}

export const noAuthentication = MyComponent => {
  class AuthenticationInterface extends Component {
    render () {
      return !this.props.user.token || !this.props.user.user ? (
        // enter when not logged in
        <MyComponent {...this.props} />
      ) : (
        <Redirect to='/' />
      ) // enter when logged in
    }
  }

  const mapStateToProps = state => ({
    user: state.user
  })

  return connect(mapStateToProps, null)(withRouter(AuthenticationInterface))
}

export const requiresFeature = (
  MyComponent,
  feature,
  plans,
  redirectOnError = true
) => {
  class FeatureInterface extends Component {
    redirect = () => {
      if (this.props.history.length > 0) {
        this.props.history.goBack()
        return null
      } else {
        return <Redirect to='/' />
      }
    }

    isPlanActive = () => feature && this.props.plan === null

    isFeatureEnabled = () => {
      const features = this.props.plan
        ? Object.entries(this.props.plan)
            .filter(item => item[0].startsWith('feature') && item[1] === 1)
            .map(item => item[0])
        : []
      return feature !== 'any' && !features.includes(`feature_${feature}`)
    }

    render () {
      if (this.isPlanActive()) {
        this.props.openPopup({
          type: 'simple',
          props: {
            title: 'Plano expirado!',
            content: this.renderContentPopupExpired()
          }
        })
        if (redirectOnError) {
          return this.redirect()
        } else {
          throw new Error('No plan')
        }
      } else if (this.isFeatureEnabled()) {
        if (redirectOnError) {
          this.props.showNegativeToast(
            'Seu plano não inclui essa funcionalidade',
            2000
          )
          return this.redirect()
        } else {
          throw new Error('Wrong permission')
        }
      } else {
        return <MyComponent {...this.props} />
      }
    }

    renderContentPopupExpired () {
      const Button = ({ route, label }) => (
        <TouchableOpacity
          style={[styles.button]}
          backgroundColor={Colors.primary}
          borderColor={Colors.dark}
          onPress={() => {
            this.props.history.push(route)
            this.props.closePopup()
          }}
        >
          <Text style={[styles.buttonLabel]}>{label}</Text>
        </TouchableOpacity>
      )
      return (
        <View style={styles.container}>
          <Text>
            Seu plano expirou. Você só poderá acessar planos, suas informações
            de cobrança e seus dados pessoais.
          </Text>
          <View style={styles.buttonContainer}>
            <Button route='/planos' label='Planos' />
            <Button route='/usuario/editar' label='Informações pessoais' />
          </View>
        </View>
      )
    }
  }

  const mapStateToProps = state => ({
    plan: state.user.plan
  })

  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        showNegativeToast: SettingsActions.showNegativeToast,
        openPopup: SettingsActions.openPopup,
        closePopup: SettingsActions.closePopup
      },
      dispatch
    )

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(FeatureInterface))
}
