import { select } from 'redux-saga/effects'

export function * showPositiveToast ({ text, duration, callback }) {
  const positiveToastShow = yield select(
    ({ settings }) => settings.positiveToastShow
  )
  if (positiveToastShow) {
    positiveToastShow(text, duration, callback)
  } else {
    console.error('Toast Positive Ref was not yet defined')
  }
}

export function * showNegativeToast ({ text, duration, callback }) {
  const negativeToastShow = yield select(
    ({ settings }) => settings.negativeToastShow
  )
  if (negativeToastShow) {
    negativeToastShow(text, duration, callback)
  } else {
    console.error('Toast Negative Ref was not yet defined')
  }
}

export function * showNeutralToast ({ text, duration, callback }) {
  const neutralToastShow = yield select(
    ({ settings }) => settings.neutralToastShow
  )
  if (neutralToastShow) {
    neutralToastShow(text, duration, callback)
  } else {
    console.error('Toast Neutral Ref was not yet defined')
  }
}
