const featuresExplanationImages = {
  coloringExplanation1: require('../Images/feature-explanation/coloring/coloring-explanation1.jpg'),
  coloringExplanation2: require('../Images/feature-explanation/coloring/coloring-explanation2.jpg'),
  coloringExplanation3: require('../Images/feature-explanation/coloring/coloring-explanation3.jpg'),
  coloringExplanation4: require('../Images/feature-explanation/coloring/coloring-explanation4.jpg'),
  coloringExplanation5: require('../Images/feature-explanation/coloring/coloring-explanation5.jpg'),
  silhouetteExplanation1: require('../Images/feature-explanation/silhouette/silhouette-explanation1.jpg'),
  silhouetteExplanation2: require('../Images/feature-explanation/silhouette/silhouette-explanation2.jpg'),
  silhouetteExplanation3: require('../Images/feature-explanation/silhouette/silhouette-explanation3.jpg'),
  silhouetteExplanation4: require('../Images/feature-explanation/silhouette/silhouette-explanation4.jpg'),
  virtualClosetExplanation1: require('../Images/feature-explanation/virtual-closet/virtual-closet-explanation1.jpg'),
  virtualClosetExplanation2: require('../Images/feature-explanation/virtual-closet/virtual-closet-explanation2.jpeg'),
  virtualClosetExplanation3: require('../Images/feature-explanation/virtual-closet/virtual-closet-explanation3.jpg'),
  virtualClosetExplanation4: require('../Images/feature-explanation/virtual-closet/virtual-closet-explanation4.jpg'),
  virtualClosetExplanation5: require('../Images/feature-explanation/virtual-closet/virtual-closet-explanation5.jpg'),
  shopListExplanation1: require('../Images/feature-explanation/shop-list/shop-list-explanation1.jpg'),
  shopListExplanation2: require('../Images/feature-explanation/shop-list/shop-list-explanation2.jpg'),
  shopListExplanation3: require('../Images/feature-explanation/shop-list/shop-list-explanation3.jpg'),
  shopListExplanation4: require('../Images/feature-explanation/shop-list/shop-list-explanation4.jpg'),
  shopListExplanation5: require('../Images/feature-explanation/shop-list/shop-list-explanation5.jpg'),
  shopListExplanation6: require('../Images/feature-explanation/shop-list/shop-list-explanation6.jpg'),
  shopListExplanation7: require('../Images/feature-explanation/shop-list/shop-list-explanation7.jpg'),
  shopListExplanation8: require('../Images/feature-explanation/shop-list/shop-list-explanation8.jpg'),
  shopListExplanation9: require('../Images/feature-explanation/shop-list/shop-list-explanation9.jpg'),
  moodboardExplanation1: require('../Images/feature-explanation/moodboard/moodboard-explanation1.jpg'),
  moodboardExplanation2: require('../Images/feature-explanation/moodboard/moodboard-explanation2.jpg'),
  moodboardExplanation3: require('../Images/feature-explanation/moodboard/moodboard-explanation3.jpg'),
  moodboardExplanation4: require('../Images/feature-explanation/moodboard/moodboard-explanation4.jpg'),
  moodboardExplanation5: require('../Images/feature-explanation/moodboard/moodboard-explanation5.jpg')
}

export default featuresExplanationImages
