import React, { Component, useEffect, useState } from 'react'
import RoundedButtonStyles from '../../Containers/Styles/RoundedButtonStyles'
import { Colors, Fonts } from '../../Themes'
import IconMaterialCommunity from 'react-native-vector-icons/MaterialCommunityIcons'
import CustomText from '../CustomText'
import ContentCarousel from '../Carousel'
import LinearGradient from 'react-native-linear-gradient'
import LoadingContainer from '../LoadingContainer'
import {
  StyleSheet,
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  Image,
  ScrollView,
  Dimensions
} from 'react-native'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SettingsRedux from '../../Redux/SettingsRedux'
import PromiseButton from '@limaslab/react-native-promise-button'

const setOverflowVisible = () => {
  document.body.setAttribute('style', 'overflow: visible;')
}

const setOverflowHidden = () => {
  document.body.setAttribute('style', 'overflow: hidden;')
}

const BasicCarouselPopup = props => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const onClose = () => {
    if (!props.isLoading && props.couldClose) {
      props.onClose()
      props.closePopup()
    }
  }

  useEffect(() => {
    setOverflowHidden()

    return () => {
      setOverflowVisible()
    }
  }, [])

  const renderButtonLoading = () => {
    return (
      <View
        style={[
          RoundedButtonStyles.button,
          props.styleButton,
          {
            height: 44,
            marginTop: 32
          }
        ]}
      >
        <ActivityIndicator size='small' color={Colors.background} />
      </View>
    )
  }

  const renderButton = () => {
    return (
      <TouchableOpacity
        style={[
          RoundedButtonStyles.button,
          props.styleButton,
          {
            height: props.textButton.length > 20 ? 56 : 44,
            marginTop: 32,
            marginBottom: props.secondaryTextButton ? 0 : 32,
            flexDirection: props.iconButton ? 'row' : 'column',
            marginBottom: 32
          }
        ]}
        onPress={() => props.buttonAction()}
      >
        {props.iconButton && props.iconButton}
        <CustomText
          style={[
            RoundedButtonStyles.buttonText,
            {
              color: props.textButtonColor ?? Colors.background,
              textAlign: 'center',
              fontSize: props.textButton.length > 20 ? 14 : 15,
              marginLeft: props.iconButton ? 12 : 0
            }
          ]}
        >
          {' '}
          {props.textButton}
        </CustomText>
      </TouchableOpacity>
    )
  }

  const computeCardAspectWidthRatio = () => {
    return props.isMobile ? 1.25 : 2
  }

  const computeCardAspectHeightRatio = () => {
    return props.isMobile ? 1.375 : 2.2
  }

  const renderImageOrGifSlide = src => {
    return (
      <View
        style={{
          height: '100%',
          display: 'flex',
          borderRadius: 8,
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <img
          src={src}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'center',
            borderRadius: 8
          }}
        />
      </View>
    )
  }

  const renderVideoSlide = src => {
    return (
      <iframe
        width='100%'
        height='100%'
        src={`https://www.youtube.com/embed/${src}`}
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
      ></iframe>
    )
  }

  const createSlideSpinner = () => {
    return (
      <LinearGradient
        colors={['#6A3977', '#834F8A', '#A36FAE']}
        style={[
          styles.carouselCard,
          {
            opacity: 0.85,
            backgroundColor: Colors.primary,
            padding: 8,
            display: 'flex'
          }
        ]}
      >
        <View style={{ flex: 1, marginTop: 62 }}>
          <LoadingContainer color='#ffffff' />
        </View>
      </LinearGradient>
    )
  }

  const renderContent = () => {
    const { textH1, showClose, titleIcon, slides } = props
    const cardAspectWidthRatio = computeCardAspectWidthRatio()
    const cardAspectHeightRatio = computeCardAspectHeightRatio()
    const windowDimensions = Dimensions.get('window')
    const height = windowDimensions.height

    return (
      <View style={styles.boxPopup}>
        {showClose && (
          <TouchableOpacity style={styles.iconClose} onPress={onClose}>
            <IconMaterialCommunity
              name='window-close'
              size={22}
              color={Colors.textColorInput}
            />
          </TouchableOpacity>
        )}
        <CustomText style={styles.textH1}>
          {props.titleIcon}
          {props.textH1}
        </CustomText>
        <ScrollView style={{ maxHeight: height - 250, marginTop: 8 }}>
          <CustomText style={styles.textH2}>{props.textH2}</CustomText>
          <View
            style={{
              width: '100%',
              maxWidth: 400,
              alignSelf: 'center'
            }}
          >
            <ContentCarousel
              setCurrentSlide={currentSlide => setCurrentSlide(currentSlide)}
              slides={slides.map(slide => {
                return slide.type === 'video'
                  ? renderVideoSlide(slide.src)
                  : renderImageOrGifSlide(slide.src)
              })}
              naturalSlideWidth={cardAspectWidthRatio}
              naturalSlideHeight={cardAspectHeightRatio}
              spinner={createSlideSpinner()}
            />
          </View>
        </ScrollView>
        {props.isLoading ? renderButtonLoading() : renderButton()}
      </View>
    )

    // const cardAspectWidthRatio = computeCardAspectWidthRatio()
    // const cardAspectHeightRatio = computeCardAspectHeightRatio()

    return {
      /* {icon && <View style={{ position: 'absolute' }}>{icon}</View>}
        {title && (
          <View style={style.titleContainer}>
            <Text style={style.title}>
              <b>{title}</b>
              {slides[state.currentSlide].description && (
                <p
                  style={{
                    fontSize: 14,
                    maxWidth: 400,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 65
                  }}
                >
                  {slides[state.currentSlide].description}
                </p>
              )}
            </Text>
          </View>
        )}
        <View
          style={{
            width: '100%',
            maxWidth: 400,
            alignSelf: 'center'
          }}
        >
          <ContentCarousel
            setCurrentSlide={currentSlide => setState({ currentSlide })}
            slides={slides.map(slide => {
              return slide.type === 'video'
                ? renderVideoSlide(slide.src)
                : renderImageOrGifSlide(slide.src)
            })}
            naturalSlideWidth={cardAspectWidthRatio}
            naturalSlideHeight={cardAspectHeightRatio}
            spinner={createSlideSpinner()}
          />
        </View>
        {isLastSlide() ? (
          <TouchableOpacity
            style={style.continueExploring}
            onPress={props.closePopup}
          >
            <Text style={style.continueExploringText}>
              Continuar explorando
            </Text>
          </TouchableOpacity>
        ) : (
          <View style={style.knowMore}>
            <Text style={style.knowMoreText}>
              Arraste para o lado e saiba mais
            </Text>
            <Icon
              name='arrow-forward'
              color={Colors.primary}
              size={25}
              style={style.knowMoreIcon}
            />
          </View>
        )}
        {showClose && (
          <TouchableOpacity
            onPress={onClose}
            style={style.closeButtonContainer}
            data-test='modal-close-button'
          >
            <View style={style.closeIconContainer}>
              <Icon name='close' color={Colors.secondary} size={32} />
            </View>
          </TouchableOpacity>
        )} */
    }
  }

  return (
    <View style={{ width: '100%', height: '100%', zIndex: 9 }}>
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.backdrop} />
      </TouchableWithoutFeedback>
      <View style={styles.container} pointerEvents='box-none'>
        {renderContent()}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: 'rgba(74, 74, 74, 0.7)',
    backdropFilter: 'blur(15px)'
  },
  container: {
    position: 'fixed',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 11,
    paddingHorizontal: 16
  },
  boxPopup: {
    width: 327,
    backgroundColor: Colors.background,
    borderRadius: 12,
    paddingHorizontal: 32
  },
  iconClose: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 9
  },
  textH1: {
    marginTop: 16,
    fontWeight: 'bold',
    fontSize: 18,
    fontFamily: Fonts.type.base
  },
  textH2: {
    marginTop: 8,
    fontSize: 14,
    fontFamily: Fonts.type.base
  },
  carouselCard: {
    height: '100%',
    display: 'flex',
    borderRadius: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
})

BasicCarouselPopup.defaultProps = {
  isLoading: false,
  onClose: () => {},
  couldClose: true,
  showClose: true
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      closePopup: SettingsRedux.closePopup
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(BasicCarouselPopup)
