const colors = {
  opaque: '#AD5D9880', // #AD5D98 with opacity: 50%
  primary: '#6C367A',
  primaryLight: '#8511A1',
  primaryWithOpacity15: '#6C367A26', // #6C367A with opacity: 15%
  burguerButton: '#33054D',
  backgroundBurguerMenu: '#8C61A9',
  lineBurguerMenu: '#FFFFFF',
  colorBurguerMenu: '#FFFFFF',
  backgroundIconBurguerMenu: '#FFFFFF',
  black: '#101010',
  white: '#fafafa',
  dark: '#20033DC7',
  secondary: '#33054D',
  secondaryLight: '#9770B1',
  secondaryDark: '#520C48',
  tertiary: '#34284e',
  gray: '#707070',
  graySoft: '#898989',
  grayMediumLighter: '#c4c4c4',
  grayMediumLight: '#b4b4b4',
  grayMedium: '#E7E7E7',
  grayMediumDark: '#C8C8C8',
  grayLight: '#F5F5F5',
  background: '#FFFFFF',
  green: '#1F8408',
  mediumGreen: '#87c184',
  red: '#d02020',
  darkPurple: '#462362',
  clear: 'rgba(0,0,0,0)',
  facebook: '#3b5998',
  transparent: 'rgba(0,0,0,0)',
  silver: '#F7F7F7',
  steel: '#CCCCCC',
  error: 'rgba(200, 0, 0, 0.8)',
  ricePaper: 'rgba(255,255,255, 0.75)',
  frost: '#D8D8D8',
  cloud: 'rgba(200,200,200, 0.35)',
  windowTint: 'rgba(0, 0, 0, 0.4)',
  panther: '#161616',
  charcoal: '#595959',
  coal: '#2d2d2d',
  bloodOrange: '#fb5f26',
  snow: 'white',
  ember: 'rgba(164, 0, 48, 0.5)',
  fire: '#e73536',
  drawer: 'rgba(30, 30, 29, 0.95)',
  eggplant: '#251a34',
  border: '#483F53',
  banner: '#5F3E63',
  text: '#E0D7E5',
  borderMoodboard: '#DBBAC6',
  softBlue: '#6388D1',
  plansSecondary: '#EBD9F6',
  plansPriceLine: '#D5AEDF',
  textColorInput: '#7A4993',
  placeholderTextColor: '#BDA4C9',
  textPurpleColor: '#6D357B',
  divider: '#CECECE',
  startBackgroundColor: '#7A49931F',
  lightGreen: '#96BD90',
  graySkeleton: '#DEDEDE',
  stepsButtonColor: '#EFE9F2',
  grayLink: '#515150',
  pastelRed: '#E74C3C',
  successSelection: '#3C893E',
  pastelPink: '#7A499314'
}

export default colors
