import { StyleSheet } from 'react-native'

import { ApplicationStyles, Colors, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  button: {
    width: '100%',
    backgroundColor: Colors.textColorInput,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 26,
    height: 52,
    paddingVertical: 8,
    paddingHorizontal: 16
  },
  secondaryButton: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.textPurpleColor,
    borderRadius: 26,
    height: 52
  },
  buttonRegisterEmail: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 26,
    height: 52,
    paddingVertical: 8,
    borderWidth: 2,
    borderColor: Colors.textColorInput
  },
  buttonText: {
    fontFamily: Fonts.type.base,
    color: Colors.background,
    fontSize: 15,
    fontWeight: '600'
  },
  smallPrimaryButton: {
    width: '100%',
    backgroundColor: Colors.textPurpleColor,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    height: 36,
    paddingVertical: 8,
    paddingHorizontal: 16
  }
})
